<template>
    <div class="pageStructure">
        <div class="allSection">
            <section class="sectionGauche pt-9 pb-15" style="background: linear-gradient(rgba(1, 174, 196, 0.36) 0px, rgba(1, 174, 196, 0));">
                <div class="container">
                    <div class="my-7">
                        <h4 class="text-center w-100">
                            <i class="fa-regular fa-robot titleIcon mr-2"></i> {{ $t('message.automation') }}
                        </h4>
                        <p class="d-inline-block w-100 text-center" style="font-size: 24px;">{{ $t('message.advantages') }}</p>
                    </div>
                </div>
            </section>
            <section class="sectionGauche" >
                <div class="container">
                    <div id="avantage" class="row">
                        <div class="col-12 col-sm-6">
                            <div class="mt-6">
                                <div class="mr-2 w-100">
                                    <div class="v-card v-card--flat v-sheet theme--light"
                                         style="background-color: transparent;">
                                        <div class="d-flex py-6">
                                            <div>
                                                <div class="crdIcon"><i>1</i>
                                                </div>
                                            </div>
                                            <div class="w-100">
                                                <div class="v-card__title">{{ $t('message.time_saving') }} :</div>
                                                <div class="v-card__text">{{ $t('message.time_saving_detail') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="disStep mr-2 w-100">
                                    <div class="v-card v-card--flat v-sheet theme--light"
                                         style="background-color: transparent;">
                                        <div class="d-flex py-6">
                                            <div>
                                                <div class="crdIcon" style="background-color: rgb(202, 107, 229);">
                                                    <i>2</i></div>
                                            </div>
                                            <div class="w-100">
                                                <div class="v-card__title">{{ $t('message.error_reduction') }} :</div>
                                                <div class="v-card__text">{{ $t('message.error_reduction_detail') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mr-2 w-100">
                                    <div class="v-card v-card--flat v-sheet theme--light"
                                         style="background-color: transparent;">
                                        <div class="d-flex py-6">
                                            <div>
                                                <div class="crdIcon" style="background-color: rgb(42, 173, 253);">
                                                    <i>3</i></div>
                                            </div>
                                            <div class="w-100">
                                                <div class="v-card__title">{{ $t('message.productivity_increase') }} :</div>
                                                <div class="v-card__text">{{ $t('message.productivity_increase_detail') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 pt-16">
<!--                            <v-img class="v-responsive theme&#45;&#45;light" width="450" cover src="https://www.objectif-crm.com/assets/images/Automatisationlead.gif"></v-img>-->
                          <v-img class="v-responsive theme--light" width="450" cover src="@/images/Automatisationlead.gif"></v-img>
                        </div>
                    </div>
                </div>
            </section>
            <section class="sectionDroite mt-16 pb-15">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-sm-6 mt-8">
                            <v-img class="v-responsive theme--light" width="450" cover src="@/images/Automatisation2.gif"></v-img>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="mt-10">
                                <div class="cloudStep mr-2 w-100">
                                    <div class="v-card v-card--flat v-sheet theme--light"
                                         style="background-color: transparent;">
                                        <div class="d-flex py-6">
                                            <div>
                                                <div class="crdIcon" style="background-color: rgb(254, 179, 1);"><i>4</i></div>
                                            </div>
                                            <div class="w-100">
                                                <div class="v-card__title">{{ $t('message.process_optimization') }} :</div>
                                                <div class="v-card__text">{{ $t('message.process_optimization_detail') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="clientStep mr-2 w-100">
                                    <div class="v-card v-card--flat v-sheet theme--light"
                                         style="background-color: transparent;">
                                        <div class="d-flex py-6">
                                            <div>
                                                <div class="crdIcon" style="background-color: rgb(239, 78, 34);"><i>5</i></div>
                                            </div>
                                            <div class="w-100">
                                                <div class="v-card__title">{{ $t('message.collaboration_improvement') }} :</div>
                                                <div class="v-card__text">{{ $t('message.collaboration_improvement_detail') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mr-2 w-100">
                                    <div class="v-card v-card--flat v-sheet theme--light"
                                         style="background-color: transparent;">
                                        <div class="d-flex py-6">
                                            <div>
                                                <div class="crdIcon" style="background-color: rgb(0, 170, 221);"><i>6</i></div>
                                            </div>
                                            <div class="w-100">
                                                <div class="v-card__title">{{ $t('message.cost_reduction') }} :</div>
                                                <div class="v-card__text">{{ $t('message.cost_reduction_detail') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="sectionGauche" style="background-color: rgb(241, 241, 241);">
                <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light">
                <div class="container">
                    <div class="row compInfo">
                        <div class="col-12 col-sm-8 pt-16">
                            <div style="border: 1px solid rgb(204, 204, 204); border-radius: 10px; padding: 8px; background-color: white;">
                                <v-img class="v-responsive theme--light" width="850" cover src="@/images/sequence.png"></v-img>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 text-center mt-7 flexMobilCube">
                            <div class="cubePar">
                                <div class="cubeAutoInfo">
                                    <div><i class="far fa-mail-bulk" aria-hidden="true"></i></div>
                                    <div>{{ $t('message.email_templates') }}</div>
                                </div>
                            </div>
                            <div class="cubePar">
                                <div class="cubeAutoInfo">
                                    <div><i class="fad fa-box-check" aria-hidden="true"></i></div>
                                    <div>{{ $t('message.create_records') }}</div>
                                </div>
                            </div>
                            <div class="cubePar">
                                <div class="cubeAutoInfo">
                                    <div><i class="far fa-money-check-edit-alt" aria-hidden="true"></i></div>
                                    <div>{{ $t('message.create_commissions') }}</div>
                                </div>
                            </div>
                            <div class="cubePar">
                                <div class="cubeAutoInfo">
                                    <div><i class="fad fa-network-wired" aria-hidden="true"></i></div>
                                    <div>{{ $t('message.post_data') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light">
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: "automationApp"
}
</script>

<style scoped>

</style>