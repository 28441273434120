<template>
  <div id="pricing" class="pageStructure">
    <div class="allSection">
      <div class="container">
        <div class="mt-9 v-card v-card--flat v-sheet theme--light">
          <div class="v-window v-item-group theme--light">
            <div class="v-window__container">
              <div class="v-window-item v-window-item--active">
                <div class="container container--fluid">
                  <section>
                    <h2 class="text-center mt-8">{{ $t('message.EnsembleProductivite') }}<br><span
                        class="gratuitt">{{ $t('message.CommencezGratuitement') }}</span>.</h2>
                    <p class="text-center m-0">{{ $t('message.PeriodeEssai') }}</p>
                    <div class="w-100 d-flex mt-7 justify-content-center align-items-center">
                      <div class="v-item-group theme--dark v-btn-toggle v-btn-toggle--dense primary">
                        <button @click="pricingType = 'annual'"
                                class="v-btn v-item--active v-btn--active v-btn--is-elevated v-btn--has-bg theme--dark v-size--default">
                          <span class="v-btn__content">{{ $t('message.annual') }}</span>
                        </button>
                        <button @click="pricingType = 'monthly'" type="button"
                                class="v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default">
                          <span class="v-btn__content">{{ $t('message.monthly') }}</span>
                        </button>
                      </div>
                    </div>
                    <div class="container p-md-0">
                      <form>
                        <div class="per-custom mt-10">
                          <div class="d-flex allProduct pt-10 align-items-center justify-content-center"
                               style="overflow-x: auto;">
                            <div class="productOne position-relative">
                              <div class="card mb-5 mb-lg-0 h-100">
                                <div class="background-price-1" style="background-color: rgb(228, 3, 165);"></div>
                                <div class="price-card-title text-center">
                                  <h4 class="color-4 fw-400 py-2 m-0"
                                      style="margin-top: 20px; color: rgb(228, 3, 165); font-size: 34px;">
                                    {{ $t('message.solo') }}</h4>
                                  <p class="font-1 color-6 mb-5" style="height: 66px; padding-top: 31px;">
                                    {{ $t('message.freeLifetime') }}</p>
                                  <div class="contentPrice text-left">
                                    <p class="ml-2">{{ $t('message.individualPlanIncludes') }}</p>
                                    <ul>
                                      <li>Espace de travail collaboratif</li>
                                      <li>100 Mo de stockage</li>
                                      <li>Acces au template, plus de 50 modèles</li>
                                      <li>10 types de champs</li>
                                      <li>Historique de la page de 10 jours</li>
                                    </ul>
                                    <!--                                                                      <ul>-->
                                    <!--                                                                            <li>{{ $t('message.collaborativeWorkspace') }}</li>-->
                                    <!--                                                                            <li>{{ $t('message.freeAPIIntegration') }}</li>-->
                                    <!--                                                                            <li>{{ $t('message.limitedTimeHistory') }}</li>-->
                                    <!--                                                                            <li>{{ $t('message.powerfulFormulas') }}</li>-->
                                    <!--                                                                        </ul>-->
                                  </div>
                                </div>
                                <div class="card-footer text-center w-100">
                                  <button type="button" @click="modalOpen=true"
                                          class="v-btn v-btn--has-bg theme--light v-size--default"
                                          style="background-color: rgb(228, 3, 165); border-color: rgb(228, 3, 165); color: white;">
                                    <span class="v-btn__content">{{ $t('message.freeTrial') }}</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="productOne position-relative bestProduct">
                              <div class="chipProduuct">
                                                              <span
                                                                  class="v-chip v-chip--no-color theme--light v-size--default">
                                                                  <span class="v-chip__content">{{
                                                                      $t('message.recommended')
                                                                    }}</span>
                                                              </span>
                              </div>
                              <div class="card mb-5 mb-lg-0 h-100" style="background-color: rgb(10, 61, 98);">
                                <div class="price-card-title text-center">
                                  <h4 class="color-4 fw-400 py-2 m-0"
                                      style="margin-top: 20px; color: rgb(255, 255, 255); font-size: 34px;">
                                    {{ $t('message.standard') }}
                                  </h4>
                                  <p class="font-1 color-6 mb-5" style="color: white;">
                                    <span>{{ getPriceById(2) }}€</span> {{ $t('message.perUserMonth') }}
                                  </p>
                                  <div class="contentPrice text-left" style="color: white;">
                                    <p class="ml-2">{{ $t('message.includesBasicPlus') }}</p>
                                    <ul>
                                      <li>{{ $t('message.calendarView') }}</li>
                                      <li>{{ $t('message.unlimitedAutomations') }}</li>
                                      <li>{{ $t('message.limitedTimeHistory7') }}</li>
                                      <li>{{ $t('message.VIPServiceManagement') }}</li>
                                      <li>{{ $t('message.permissionManagementAllLevels') }}</li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="card-footer text-center w-100">
                                  <button type="button" class="v-btn v-btn--has-bg theme--light v-size--default"
                                          style="background-color: rgb(255, 255, 255); border-color: rgb(255, 255, 255); color: black;">
                                    <span class="v-btn__content">{{ $t('message.freeTrial') }}</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="productOne position-relative">
                              <div class="card mb-5 mb-lg-0 h-100">
                                <div class="background-price-1" style="background-color: rgb(0, 194, 203);"></div>
                                <div class="price-card-title text-center">
                                  <h4 class="color-4 fw-400 py-2 m-0"
                                      style="margin-top: 20px; color: rgb(0, 194, 203); font-size: 34px;">
                                    {{ $t('message.pro') }}
                                  </h4>
                                  <p class="font-1 color-6 mb-5">
                                    <span>{{ getPriceById(3) }}€</span> {{ $t('message.perUserMonth') }}
                                  </p>
                                  <div class="contentPrice text-left">
                                    <p class="ml-2">{{ $t('message.includesStandardPlus') }}</p>
                                    <ul>
                                      <li>{{ $t('message.documentVisualizationSAS') }}</li>
                                      <li>{{ $t('message.fileStorage15') }}</li>
                                      <li>{{ $t('message.premiumSupport') }}</li>
                                      <li>{{ $t('message.createDashboard') }}</li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="card-footer text-center w-100">
                                  <button type="button" class="v-btn v-btn--has-bg theme--light v-size--default"
                                          style="background-color: rgb(0, 194, 203); border-color: rgb(0, 194, 203); color: white;">
                                    <span class="v-btn__content">{{ $t('message.freeTrial') }}</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="productOne position-relative">
                              <div class="card mb-5 mb-lg-0 h-100">
                                <div class="background-price-1" style="background-color: black;"></div>
                                <div class="price-card-title text-center">
                                  <h4 class="color-4 fw-400 py-2 m-0"
                                      style="margin-top: 20px; color: rgb(0, 0, 0); font-size: 34px;">
                                    {{ $t('message.enterprise') }}
                                  </h4>
                                  <p class="font-1 color-6 mb-5"><span style="height: 53px;"></span>
                                    {{ $t('message.custom') }}</p>
                                  <div class="contentPrice text-left">
                                    <p class="ml-2">{{ $t('message.includesProPlus') }}</p>
                                    <ul>
                                      <li>{{ $t('message.unlimitedPlugins') }}</li>
                                      <li>{{ $t('message.unlimitedFileStorage') }}</li>
                                      <li>{{ $t('message.unlimitedSetupIntegration') }}</li>
                                      <li>{{ $t('message.unlimitedTimeHistory') }}</li>
                                      <li>{{ $t('message.powerfulFormulas') }}</li>
                                      <li>{{ $t('message.onDemandDevelopment') }}</li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="card-footer text-center w-100">
                                  <button type="button" class="v-btn v-btn--has-bg theme--light v-size--default black"
                                          style="color: white;">
                                    <span class="v-btn__content">{{ $t('message.contactUs') }}</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <v-card id="fonctionList" flat class="" style="margin-top: 75px">
                            <v-card-title class="justify-content-center"><h2>{{ $t('message.featuresList') }}</h2>
                            </v-card-title>
                            <v-card-text class="p-0">
<!--                              <v-simple-table>-->
<!--                                <template v-slot:default>-->
<!--                                  <thead class="sticky-header">-->
<!--                                  <tr>-->
<!--                                    <th class="">Fonctionnalité</th>-->
<!--                                    <th class="text-center" style="color: rgb(228, 3, 165); font-size: 22px;">Gratuit</th>-->
<!--                                    <th class="text-center" style="color: rgb(10,61,98); font-size: 22px;">Plus</th>-->
<!--                                    <th class="text-center" style="color: rgb(0,193,202); font-size: 22px;">Pro</th>-->
<!--                                    <th class="text-center" style="color: rgb(0,0,0); font-size: 22px;">Entreprise</th>-->
<!--                                  </tr>-->
<!--                                  </thead>-->
<!--                                  <tbody>-->
<!--                                  <tr v-for="(feature, index) in features" :key="index">-->
<!--                                    <td class="bold">{{ feature.name }}</td>-->
<!--                                    <td class="text-center"><span v-if="feature.free === 'Oui'"><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span><span v-else>{{ feature.free }}</span></td>-->
<!--                                    <td class="text-center"><span v-if="feature.plus === 'Oui'"><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span><span v-else>{{ feature.plus }}</span></td>-->
<!--                                    <td class="text-center"><span v-if="feature.pro === 'Oui'"><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span><span v-else>{{ feature.pro }}</span></td>-->
<!--                                    <td class="text-center"><span v-if="feature.enterprise === 'Oui'"><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span><span v-else>{{ feature.enterprise }}</span></td>-->
<!--                                  </tr>-->

<!--                                  </tbody>-->
<!--                                </template>-->
<!--                              </v-simple-table>-->
                              <v-simple-table>
                                <thead class="sticky-header">
                                <tr>
                                  <th class="section-fonction">{{ $t('message.content') }}</th>
                                  <th class="text-center" style="color: rgb(228, 3, 165); font-size: 22px;">{{ $t('message.free') }}</th>
                                  <th class="text-center" style="color: rgb(10, 61, 98); font-size: 22px;">{{ $t('message.plus') }}</th>
                                  <th class="text-center" style="color: rgb(0, 193, 202); font-size: 22px;">{{ $t('message.pro') }}</th>
                                  <th class="text-center" style="color: rgb(0, 0, 0); font-size: 22px;">{{ $t('message.enterprise') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td class="bold">{{ $t('message.numberOfBoards') }}</td>
                                  <td class="text-center"><span>4</span></td>
                                  <td class="text-center"><span>20</span></td>
                                  <td class="text-center"><span>{{ $t('message.unlimited') }}</span></td>
                                  <td class="text-center"><span>{{ $t('message.unlimited') }}</span></td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.proFieldTypes') }}</td>
                                  <td class="text-center"><span></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.fileUpload') }}</td>
                                  <td class="text-center"><span>5 MB</span></td>
                                  <td class="text-center"><span>{{ $t('message.unlimited') }}</span></td>
                                  <td class="text-center"><span>{{ $t('message.unlimited') }}</span></td>
                                  <td class="text-center"><span>{{ $t('message.unlimited') }}</span></td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.documentStorage') }}</td>
                                  <td class="text-center"><span>100 MB</span></td>
                                  <td class="text-center"><span>5 Go</span></td>
                                  <td class="text-center"><span>15 Go</span></td>
                                  <td class="text-center"><span>100 Go</span></td>
                                </tr>
                                <tr>
                                  <td class="section-fonction" colspan="5">{{ $t('message.organization') }}</td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.businessUnit') }}</td>
                                  <td class="text-center"><span>1</span></td>
                                  <td class="text-center"><span>10</span></td>
                                  <td class="text-center"><span>{{ $t('message.unlimited') }}</span></td>
                                  <td class="text-center"><span>{{ $t('message.unlimited') }}</span></td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.role') }}</td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.advancedPermissionManagement') }}</td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.user') }}</td>
                                  <td class="text-center"><span>{{ $t('message.max2') }}</span></td>
                                  <td class="text-center"><span>{{ $t('message.min3') }}</span></td>
                                  <td class="text-center"><span>{{ $t('message.min3') }}</span></td>
                                  <td class="text-center"><span>{{ $t('message.min10') }}</span></td>
                                </tr>
                                <tr>
                                  <td class="section-fonction" colspan="5">{{ $t('message.templatesAndPlugins') }}</td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.basicTemplate') }}</td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.proTemplate') }}</td>
                                  <td class="text-center"><span></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.basicPlugins') }}</td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.proPlugins') }}</td>
                                  <td class="text-center"><span></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.expertPlugins') }}</td>
                                  <td class="text-center"><span></span></td>
                                  <td class="text-center"><span></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                </tr>
                                <tr>
                                  <td class="section-fonction" colspan="5">{{ $t('message.sharingAndCollaboration') }}</td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.publicAPI') }}</td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.automationTasks') }}</td>
                                  <td class="text-center"><span>100</span></td>
                                  <td class="text-center"><span>400</span></td>
                                  <td class="text-center"><span>30.000</span></td>
                                  <td class="text-center"><span>100.000</span></td>
                                </tr>
                                <tr>
                                  <td class="section-fonction" colspan="5">{{ $t('message.viewsAndProcessing') }}</td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.dataCenterView') }}</td>
                                  <td class="text-center"><span>10</span></td>
                                  <td class="text-center"><span>20</span></td>
                                  <td class="text-center"><span>26</span></td>
                                  <td class="text-center"><span>40</span></td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.mapAndGeolocation') }}</td>
                                  <td class="text-center"><span></span></td>
                                  <td class="text-center"><span></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.kanbanViews') }}</td>
                                  <td class="text-center"><span></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.calendarAndPlanning') }}</td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                </tr>
                                <tr>
                                  <td class="section-fonction" colspan="5">{{ $t('message.branding') }}</td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.whiteLabel') }}</td>
                                  <td class="text-center"><span></span></td>
                                  <td class="text-center"><span></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                </tr>
                                <tr>
                                  <td class="bold">{{ $t('message.premiumSupport') }}</td>
                                  <td class="text-center"><span></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                  <td class="text-center"><span><v-icon small class="icon-table">fa-duotone fa-solid fa-check</v-icon></span></td>
                                </tr>
                                </tbody>
                              </v-simple-table>

                            </v-card-text>
                          </v-card>
                        </div>
                      </form>
                      <div class="my-6 mt-16 text-center">
                        <h5>{{ $t('message.note') }}</h5>
                        <i>{{ $t('message.someAppsNote') }}<a
                            @click="navigateToRefresh('integrations')">{{ $t('message.here') }}</a></i>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <!---->
            </div>
          </div>
        </div>
        <table-compare/>

        <frequently-asked/>

      </div>
      <section-pub/>
    </div>
    <modal-lead :modal-lead.sync="modalOpen"/>
  </div>
</template>

<script>
import ModalLead from "@/components/modal/modalLead.vue";
import SectionPub from "@/components/micro/sectionPub.vue";
import TableCompare from "@/components/micro/tableCompare.vue";
import FrequentlyAsked from "@/components/micro/frequentlyAsked.vue";

export default {
  name: "pricingApp",
  components: {FrequentlyAsked, TableCompare, SectionPub, ModalLead},

  data() {
    return {
      // features: [
      //   { name: "Nombre maximum d'utilisateurs", free: "2", basic: "10", standard: "Illimité", pro: "Illimité" },
      //   { name: "Stockage de fichiers", free: "500MB", basic: "5GB", standard: "20GB", pro: "100GB" },
      //   { name: "Tableaux illimités", free: "Non", basic: "Oui", standard: "Oui", pro: "Oui" },
      //   { name: "Automatisations", free: "250 actions", basic: "25,000 actions", standard: "250,000 actions", pro: "Illimité" },
      // ],
      // features: [
      //   {name: "API publique", free: "Oui", plus: "Oui", pro: "Oui", enterprise: "Oui"},
      //   {name: "Templete basic", free: "Oui", plus: "Oui", pro: "Oui", enterprise: "Oui"},
      //   {name: "Template pro", free: "", plus: "Oui", pro: "Oui", enterprise: "Oui"},
      //   {name: "Application et plug-in basic", free: "Oui", plus: "Oui", pro: "Oui", enterprise: "Oui"},
      //   {name: "Application et plug-in pro", free: "", plus: "Oui", pro: "Oui", enterprise: "Oui"},
      //   {name: "Application et plug-in expert", free: "", plus: "", pro: "Oui", enterprise: "Oui"},
      //   {name: "Vues Pro", free: "", plus: "Oui", pro: "Oui", enterprise: "Oui"},
      //   {name: "Types de champ pro", free: "", plus: "Oui", pro: "Oui", enterprise: "Oui"},
      //   {name: "Assistance premium", free: "", plus: "Oui", pro: "Oui", enterprise: "Oui"},
      //   {name: "Geolocalisation, Adresse precision, Map, Distance", free: "", plus: "", pro: "Oui", enterprise: "Oui"},
      //   {name: "Automatisation (task)", free: "100", plus: "400", pro: "30.000", enterprise: "100.000"},
      //   {name: "Chargement de fichiers", free: "5 MB", plus: "Illimitée", pro: "Illimitée", enterprise: "Illimitée"},
      //   {name: "Stockage documents", free: "100 MB", plus: "5 Go", pro: "15 Go", enterprise: "100 Go"},
      //   {name: "Datacenter colonne", free: "10", plus: "20", pro: "26", enterprise: "40"},
      //   {name: "Unitee d'afaire", free: "1", plus: "10", pro: "Illimitée", enterprise: "Illimitée"},
      //   {name: "Utilisateur", free: "Max 2", plus: "Min 3", pro: "Min 3", enterprise: "Min 10"},
      //   {name: "Nombre de tableaux", free: "4", plus: "20", pro: "Illimitée", enterprise: "Illimitée"},
      //   {name: "Marque blanche (URL)", free: "", plus: "", pro: "Oui", enterprise: "Oui"},
      //   {name: "Calendrier et planning", free: "Oui", plus: "Oui", pro: "Oui", enterprise: "Oui"},
      //   {name: "Gestion des permissions avancée", free: "Oui", plus: "Oui", pro: "Oui", enterprise: "Oui"},
      // ],

      valid: true,
      modalOpen: false,
      products: [
        {id: 1, name: 'Plus', annualPrice: 8, monthlyPrice: 10},
        {id: 2, name: 'Pro', annualPrice: 10, monthlyPrice: 12},
        {id: 3, name: 'Business', annualPrice: 16, monthlyPrice: 20},
      ],
      pricingType: 'annual',
    };
  },

  methods: {
    getPriceById(id) {
      console.log(JSON.stringify(this.displayedProducts))
      return typeof this.displayedProducts.find(p => p.id === id) === "undefined" ? 0 : this.displayedProducts.find(p => p.id === id).price;
    },
    navigateTo(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({name: routeName});
      }
    },
    navigateToRefresh(routeName) {
      if (this.$route.name !== routeName) {
        window.location = routeName;
      }
    },

  },
  computed: {
    displayedProducts() {
      return this.products.map(product => {
        return {
          ...product,
          price: this.pricingType === 'monthly' ? product.monthlyPrice : product.annualPrice,
        }
      });
    },
  },
}
</script>

<style scoped>

</style>