export default {
    message: {
        //fin
        construct: 'En construction -> 30/06',
        sloganPH1: 'Le logiciel de travail no-code qui s\'adapte à votre entreprise',
        applicativeServices: 'Services applicatifs',
        function: 'Fonction',
        manageCommunicateAutomate: 'Gérez, communiquez, automatisez vos procédures grâce à nos services',
        betoolServices: "Les services de beTool sont les canaux d'exécution fonctionnelle qui permettent une meilleure gestion de l'harmonie du travail.",
        concreteExamples: 'Exemples concrets :',
        dynamicForm: 'Formulaire dynamique',
        productCatalogue: 'Catalogue produit',
        calendar: 'Calendrier',
        accounting: 'Comptabilité',
        learnMore: 'En savoir plus',
        appsPlugins: 'Applications et plugins',
        connectivity: 'Connectivité',
        connectActivate: 'Activez et connectez des fonctionnalités variées grâce à des applications et extensions adaptées.',
        appsProposed: 'Les applications proposées facilitent la gestion des relations avec des applications ou des systèmes externes, favorisant ainsi la centralisation des données et des fonctionnalités.',
        collaboratorsManagement: 'Gestion des collaborateurs',
        optimization: 'Optimisation',
        optimizeTeams: 'Optimisez vos équipes et leur production',
        managementRights: 'La gestion des collaborateurs en matière de droits et de permissions est cruciale pour une productivité optimale et une gestion précise des rôles.',
        taskManagement: 'Gestion des tâches',
        projectManagement: 'Gestion de projet',
        automatedWorkProcesses: 'Processus de travail automatisés',
        createYours: 'Créez le vôtre',
        dynamism: 'Dynamisme',
        createDatabase: 'Créez vos bases de données grâce à une administration dynamique et no-code',
        dynamicCreationTool: 'Avec l\'outil de création dynamique, la création de modèles de données sur mesure vous permet de collaborer et d\'éditer des documents en toute simplicité.',
        crm: 'CRM',
        erp: 'ERP',
        kanban: 'Kanban',
        ticketing: 'Ticketing',
        applicationServices: 'SERVICES APPLICATIFS',
        services: 'Services',
        allInOne: 'Tout en un',
        servicesDescription: 'Nos services permettent de simplifier la gestion de vos données en proposant des structures complexes préconçues, adaptées à différents secteurs d\'activité. Ils vous aident à créer des bases de données performantes et à maximiser l\'utilisation de l\'outil grâce à des formations sur mesure. En somme, nos services sont essentiels pour gérer, communiquer et automatiser vos procédures.',
        appAndPlugins: 'APPLICATIONS ET PLUGINS',
        integration: 'Intégrations',
        effectiveCommunication: 'Intégrez des moyens de communication efficaces',
        integrationDescription: 'Personnalisez facilement votre CRM afin qu\'il travaille pour vous, sans avoir besoin de faire appel à un développeur. Modifiez les étapes de signature de contrat, ajoutez autant de colonnes que vous le souhaitez, gérez plusieurs pipelines à la fois, et bien plus encore.',
        moreThan100Plugins: 'Plus de 100 plugins',
        discoverIntegrations: 'Découvrez les intégrations et plugins',
        collaboratorManagement: 'Gestion des collaborateurs',
        collaboration: 'Collaboration',
        collaborateToSucceed: 'Collaborez pour réussir et innover',
        collaborationDescription: 'Une bonne collaboration permet de centraliser toutes les informations relatives à un projet, telles que les tâches, les délais, les commentaires, les fichiers, les messages, etc. Cela permet à chaque utilisateur de l\'équipe d\'accéder rapidement et facilement à toutes les informations dont il a besoin pour accomplir son travail. Elle permet également de structurer l\'équipe de manière à ce que chaque utilisateur ait un rôle clair et des responsabilités définies. Cela permet d\'optimiser la collaboration et de réduire les erreurs de traitement.',
        customizableToMatch: 'Personnalisable pour correspondre à votre cycle de vente',
        customizationDescription: 'Grâce à notre plateforme no-code, vous pouvez personnaliser facilement vos processus et créer des scénarios d\'automatisation sur mesure, sans recourir à un développeur. Vous pouvez modifier les étapes, ajouter autant de données que vous le souhaitez et gérer plusieurs collaborateurs sur plusieurs pipelines simultanément. Tout cela vous permet d\'être autonome et d\'utiliser notre plateforme sans nécessiter l\'intervention d\'un développeur.',
        discoverProcessData: 'Découvrez process data',
        home: "Accueil",
        applicationsPlugins: "Applications et Plugins",
        features: "Fonctionnalités",
        automations: "Les Automatisations",
        integrations: "Les Intégrations",
        views: "Les Vues",
        electronicSignature: "La Signature Électronique",
        client: "Client",
        login: "Connexion",
        termsOfUse: "CGU",
        support: "Support",
        contact: "Contact",
        underConstruction: "Site en construction / Prévu pour le 30/06/23",
        connectActivateFeatures: 'Connectez et activez des fonctionnalités grâce à des applications et plugins en tout genre.',
        api: 'API',
        applications: 'Applications',
        synchronization: 'Synchronisation',
        createDatabasesDynamicNOCODE: 'Créez vos bases de données grâce à une administration dynamique et no-code.',
        tickets: 'Tickets',
        freeTrial: 'Essai gratuit',
        signup: 'Inscription',

        tasks: 'Tâches',
        hello: 'Bonjour',
        tools: 'Outils',
        toolbox: 'Boîte à outils',
        appServices: 'Services applicatifs',
        email: 'Email',
        documents: 'Documents',
        sms: 'SMS',
        optimizeTeamsProduction: 'Optimisez vos équipes et leur production',
        roles: 'Rôles',
        permissions: 'Permissions',
        letAutomationsWork: 'Laissez les automatisations travailler pour vous et concentrez-vous sur l\'essentiel',
        simplifyWorkflow: 'Simplifiez votre flux de travail grâce aux intégrations',
        maximizeProductivityEfficiency: 'Maximisez votre productivité et votre efficacité grâce aux vues bien conçues',
        saveTimeEnvironment: 'Gagnez du temps pour vous et pour votre environnement, plus facile et plus sûr',
        pricing: 'Prix',
        contactUs: 'Nous contacter',
        maximizeProductivity: 'Maximisez votre productivité et votre efficacité grâce aux vues bien conçues',
        company: 'Entreprise',
        aboutUs: 'À propos de nous',
        whyBeTool: 'Pourquoi beTool ?',
        whatIsNocode: 'Qu\'est-ce que le no-code ?',
        supportAide: 'Aides Support',
        technicalSupport: 'Technique',
        salesSupport: 'Commercial',
        productTools: 'Produits outils',
        legal: 'Légal',
        dmca: 'DMCA',
        CGU: 'Conditions générales d\'utilisation',
        privacyPolicy: 'Politique de confidentialité',
        noCreditCard: 'Aucune carte de crédit requise',
        tryFree: 'Essayer gratuitement',
        allRightsReserved: 'Tous droits réservés.',
        // ic1

        description: 'Optimisez la gestion des projets professionnels, tels que la gestion collaborative, la gestion des prospects et des relations clients de votre entreprise, grâce à une solution ludique, 100% dynamique et sans code "no-code".',
        emailPlaceholder: 'Email',
        crdTitle1: 'Moduler mon environnement de travail',
        crdTxt1: 'Une solution no-code pleine de ressources.',
        crdAction1: "Qu'est-ce que le no-code ?",
        crdTitle2: 'Essai gratuit sans engagement',
        crdTxt2: 'Nous vous offrons une période d\'essai de 15 jours',
        crdAction2: "J'essaie",
        sectionTitle: 'Tout-en-un',
        card1Title: 'Gérez votre équipe de travail',
        card1Text: 'Modulez et améliorez la collaboration de vos équipes et obtenez une visibilité complète sur les processus de production à l\'aide de fonctionnalités avancées adaptées à vos besoins.',
        card2Title: 'Créez des procédures de gestion',
        card2Text: 'Créez facilement des objets de gestion grâce à des widgets fonctionnels et applicatifs en drag & drop, tout en maîtrisant les permissions des intervenants.',
        card3Title: 'Profitez de services et d\'applications',
        card3Text: 'L’interface intuitive propose des services tels que : Email, SMS, Produits, Calendrier et plus encore.',
        card3Link1: 'Services ?',
        card3Link2: 'Intégrations ?',
        wyBetool: 'Pourquoi choisir beTool ?',
        point1Title: 'Une solution no-code',
        point1Text: 'Modulable facilement en drag & drop',
        point2Title: '100% autonome',
        point2Text: 'Un paramétrage simple et guidé',
        point3Title: 'Plus de 30 thèmes templates',
        point3Text: 'Thèmes adaptés à votre métier / travail',
        point4Title: 'Option de partage',
        point4Text: 'Partagez vos objets de travail facilement',
        buttonText: 'Commencer l\'essai gratuit',
        integrationSectionTitle: 'Grâce aux applications et plugins, restez connecté à vos API préférées',
        integrationSectionText: 'Finalisez vos projets et accélérez leur progression sans quitter votre application. Améliorez la synchronisation, l\'efficacité et la productivité de votre équipe en vous connectant à des prestataires intégrés à la solution.',
        integrationSectionPlugins: 'Plus de 40 plugins',
        integrationSectionButton: 'Découvrez les intégrations de beTool',
        sectionPubTitle: 'Votre logiciel de travail sur mesure',
        sectionPubText: 'Profitez d\'un essai gratuit de 15 jours sans obligation de fournir une carte de crédit.',
        sectionPubButton: 'J\'y vais',
        sectionPricingTitle: 'Obtenir plus au meilleur prix',
        sectionPricingTableHeader: 'Ce graphique représente une liste partielle des fonctionnalités disponibles dans signNow, DocuSign, Adobe Sign et HelloSign.',
        featureAccounting: 'Gérez la comptabilité de votre/vos entreprise(s)',
        featureMarketing: 'Marketing',
        featureEmail: 'Email',
        featureSms: 'SMS',
        packagePricePerUser: 'Prix du forfait par user',
        startFreeTrial: 'Commencez l\'essai gratuit',
        title: 'Support et assistances',
        apis: "API",
        apiDescription: "Une documentation API est disponible pour tous les besoins d'import, d'export ou de synchronisation",
        tutorials: 'Tutoriels',
        tutorialsDescription: "Découvrez toutes les astuces et méthodes pour maîtriser l'outil grâce à des tutoriels disponibles dans l'espace dédié",
        assistance: 'Assistances',
        assistanceDescription: "Notre équipe d'assistants techniques et commerciaux se tient à votre disposition pour vous aider dans vos démarches",
        beReady: 'Préparez-vous à en obtenir davantage',
        getAccessNow: 'Obtenez dès à présent vos accès !',
        startTrial: "Démarrer l'essai",
        requestCallback: 'Demande de rappel',

        // Page automation
        automation: 'Les automatisations',
        advantages: 'Les avantages',
        time_saving: 'Gain de temps',
        time_saving_detail: 'L\'automatisation permet de gagner du temps en éliminant les tâches manuelles et répétitives, telles que la collecte et la saisie de données, l\'envoi de rappels ou la mise à jour des statuts. Cela permet aux utilisateurs de l\'équipe de se concentrer sur des tâches à plus forte valeur ajoutée.',
        error_reduction: 'Réduction des erreurs',
        error_reduction_detail: 'Les tâches automatisées sont moins sujettes aux erreurs humaines, ce qui permet de réduire les risques d\'erreur et d\'améliorer la qualité des résultats.',
        productivity_increase: 'Augmentation de la productivité',
        productivity_increase_detail: 'L\'automatisation permet de libérer du temps pour des tâches plus importantes, ce qui permet d\'augmenter la productivité de l\'équipe.',
        process_optimization: 'Optimisation des processus',
        process_optimization_detail: 'L\'automatisation peut également aider à optimiser les processus en identifiant les goulots d\'étranglement et les inefficacités. En optimisant les processus, l\'équipe peut réduire les coûts et améliorer la qualité des résultats.',
        collaboration_improvement: 'Amélioration de la collaboration',
        collaboration_improvement_detail: 'L\'automatisation peut faciliter la collaboration en éliminant les tâches manuelles et en permettant aux membres de l\'équipe de se concentrer sur la collaboration et la communication.',
        cost_reduction: 'Réduction des coûts',
        cost_reduction_detail: 'L\'automatisation peut réduire les coûts en éliminant les tâches manuelles et en optimisant les processus. Cela permet également de réduire les risques d\'erreur et de retard, ce qui peut réduire les coûts globaux du projet.',
        email_templates: 'Envoyer des emails via des templates',
        create_records: 'Créer des enregistrements vers d\'autres processus',
        create_commissions: 'Créer des commissions grâce à des règles prévues',
        post_data: 'Faire un POST de données maîtrisées vers un système INT/EXT',

        // about_us
        about_us: 'À propos de nous',
        introduction_title: 'Introduction',
        introduction_text: "Notre entreprise est dédiée à fournir aux entrepreneurs un outil de gestion d'entreprise révolutionnaire. Notre solution est complètement modulable et ne nécessite aucune connaissance en développement informatique no-code. Nous sommes passionnés par l'idée de proposer une administration fluide et ludique, dans l'air du temps, pour que les entrepreneurs puissent gérer tous leurs processus à partir d'un seul outil. Nous croyons en la force de la technologie pour aider les entreprises à prospérer et nous sommes fiers de proposer une solution scalable et modulable, avec une grande maîtrise des données.",
        history_title: 'Histoire',
        history_text: "<strong>beTool</strong> a été créée en 2018 avec un objectif modeste : créer un petit outil pour un secteur spécifique. Au fil du temps, grâce à nos clients, nous avons compris que le monde du digital grandissait et nécessitait de la modularité, de l'efficacité, de la connectivité et bien d'autres atouts pour répondre aux besoins des entrepreneurs. C'est ainsi qu'en 2020, nous avons recommencé notre projet 'page blanche' en créant une architecture capable de répondre à une grande partie des attentes de nos clients, dans le but de leur proposer tout ce dont ils ont besoin. Nous sommes fiers de notre parcours et nous continuerons à écouter nos clients et à innover pour leur offrir les meilleurs outils de gestion d'entreprise.",
        team_title: 'Team',
        administration_title: 'Administration',
        developers_title: 'Développeurs',
        values: 'Valeurs',
        innovationCreativity: 'Innovation et créativité',
        innovationCreativityDescription: 'Nous sommes passionnés par l\'innovation et la créativité, et nous travaillons chaque jour pour améliorer nos produits et services. Nous sommes constamment à l\'écoute des besoins de nos clients et nous cherchons toujours de nouvelles solutions pour répondre à leurs demandes.',
        transparencyIntegrity: 'Transparence et intégrité',
        transparencyIntegrityDescription: 'Nous sommes engagés à maintenir des relations transparentes et honnêtes avec nos clients, nos partenaires et nos employés. Nous sommes fiers de notre intégrité et nous sommes toujours ouverts aux commentaires et aux critiques constructives de nos clients pour nous améliorer.',
        connectivityDescription: 'Nous visons l\'excellence dans tout ce que nous faisons, de la conception de nos produits à la qualité de notre service client. Nous sommes déterminés à offrir une expérience exceptionnelle à nos clients à tous les niveaux.',
        excellence: 'Excellence',
        excellenceDescription: 'Nous sommes convaincus que la connectivité est essentielle pour permettre à nos clients de rester compétitifs sur un marché en constante évolution. Nous offrons des solutions qui permettent à nos clients de connecter leurs différents processus, outils et systèmes afin d\'optimiser leur fonctionnement.',
        sustainability: 'Durabilité',
        sustainabilityDescription: 'Nous sommes conscients de l\'impact de nos activités sur l\'environnement et nous sommes engagés à minimiser notre empreinte écologique. Nous cherchons constamment à trouver des solutions durables pour nos produits et services, tout en contribuant à la réussite de nos clients.',
        produits_services: 'Produits ou services',
        produit_betooll: 'Notre produit : beTool',
        produit_betooll_description: 'Nous sommes fiers de présenter notre produit, beTool, un outil de gestion d\'entreprise tout-en-un, modulable et no-code. Avec beTool, les entrepreneurs peuvent gérer facilement les processus de leur entreprise à partir d\'un seul outil. Nous offrons une administration fluide et ludique, qui est toujours à jour avec les dernières tendances du marché, pour que nos clients bénéficient d\'une expérience utilisateur optimale.',
        flexibilite: 'Flexibilité',
        flexibilite_description: 'Nous sommes conscients que chaque entreprise est unique, c\'est pourquoi nous offrons des solutions flexibles qui peuvent être personnalisées pour répondre aux besoins spécifiques de chaque entreprise. Avec beTool, nos clients peuvent choisir les modules qu\'ils souhaitent utiliser, pour une solution sur mesure qui répond à leurs besoins.',
        connectivite: 'Connectivité',
        connectivite_description: 'Nous sommes convaincus que la connectivité est essentielle pour le succès des entreprises aujourd\'hui. C\'est pourquoi nous avons développé beTool pour qu\'il se connecte facilement avec d\'autres applications et outils d\'entreprise. Nous sommes déterminés à offrir une expérience utilisateur optimale à nos clients grâce à une connectivité maximale.',
        scalabilite: 'Scalabilité',
        scalabilite_description: 'Nous comprenons que les besoins des entreprises peuvent évoluer avec le temps, c\'est pourquoi nous offrons une solution évolutive et modulable. Nos clients peuvent ajouter ou supprimer des modules en fonction de l\'évolution de leurs besoins, sans avoir à changer de plateforme.',
        support_commercial_technique: 'Support commercial et technique',
        service_client_qualite: 'Service client de qualité',
        service_client_qualite_description: 'Nous sommes fiers d\'offrir un service client exceptionnel à nos clients. Notre équipe de support est disponible pour répondre aux questions et aider les clients à tout moment. Nous sommes déterminés à offrir une expérience client exceptionnelle à tous nos clients.',
        service_commercial: 'Service commercial',
        service_commercial_description: 'Notre équipe commerciale est là pour aider les clients à trouver les solutions beTool les mieux adaptées à leurs besoins. Nous comprenons que chaque entreprise a des besoins uniques et nous sommes déterminés à trouver la solution beTool qui répond le mieux à ces besoins.',
        consultation_gratuite: 'Consultation gratuite',
        consultation_gratuite_description: 'Nous offrons une consultation gratuite pour aider les entreprises à comprendre comment beTool peut les aider à atteindre leurs objectifs commerciaux. Nos consultants sont disponibles pour discuter des besoins de l\'entreprise et proposer des solutions beTool qui peuvent aider à résoudre les problèmes et améliorer l\'efficacité.',
        formation_support: 'Formation et support',
        formation_support_description: 'Nous offrons une formation approfondie à tous les utilisateurs beTool pour garantir qu\'ils utilisent la plateforme de manière optimale. Notre équipe de support est également disponible pour répondre à toutes les questions et résoudre les problèmes. Nous sommes déterminés à aider les entreprises à maximiser leur utilisation de beTool et à en tirer le meilleur parti possible.',
        partenariats_commerciaux: 'Partenariats commerciaux',
        partenariats_commerciaux_description: 'Nous sommes ouverts à établir des partenariats avec d\'autres entreprises pour offrir des solutions beTool qui répondent aux besoins de leurs clients. Si vous êtes intéressé par un partenariat commercial, n\'hésitez pas à nous contacter pour discuter de la manière dont nous pouvons travailler ensemble pour offrir des solutions de gestion d\'entreprise optimales.',

// no-code
        nocode_title: 'Le no-code, c\'est quoi ? Et c\'est pour qui ?',
        nocode_description: 'La révolution du no-code est en train de transformer la manière dont les entreprises abordent le développement d\'applications et la gestion de leur infrastructure informatique. Cette approche démocratise l\'innovation et permet aux entreprises de créer des solutions sur mesure pour répondre à leurs besoins spécifiques, tout en libérant les développeurs pour travailler sur des projets plus complexes et à forte valeur ajoutée.',
        intro_title: 'Introduction au no-code',
        intro_description1: 'Une plateforme de construction de logiciel no-code est un outil puissant pour les entreprises qui souhaitent développer rapidement des applications professionnelles sans avoir besoin de connaissances en programmation. Cette plateforme permet aux utilisateurs de créer des logiciels de travail et de gestion de projet en utilisant uniquement des éléments visuels, tels que des formulaires, des tableaux et des processus de travail prédéfinis. Cela signifie que les équipes peuvent développer des applications sur mesure pour répondre à leurs besoins uniques, sans avoir à dépendre d\'un développeur.',
        intro_description2: 'Avec une plateforme no-code, les utilisateurs peuvent construire des applications en quelques heures, plutôt qu\'en semaines ou en mois, ce qui leur permet de gagner du temps et de l\'argent. De plus, les applications développées à l\'aide de cette plateforme sont souvent plus flexibles et adaptables que les applications construites à partir de zéro. Les utilisateurs peuvent ajouter et supprimer des fonctionnalités, modifier des processus de travail et personnaliser les interfaces utilisateur en quelques clics, sans avoir à modifier le code source de l\'application.',
        advantages_title: 'Les avantages',
        limit_skills: 'Pas de limites de compétences :',
        cost_reduction2: 'Coût réduit :',
        flexibility: 'Flexibilité :',
        scalability: 'Évolutivité :',
        market_speed: 'Rapidité de mise sur le marché :',
        no_maintenance: 'Pas de maintenance :',
        reasons_title: 'Les raisons de passer au no-code',
        limit_skills_description: 'Le no-code permet à des personnes sans formation en codage de créer des applications et des sites web. Cela signifie que les entreprises peuvent créer des outils internes sans avoir à embaucher des développeurs, ou que les individus peuvent lancer des projets sans avoir à investir dans des compétences techniques coûteuses.',
        time_saving_point1: 'Les outils no-code sont souvent dotés de fonctionnalités prêtes à l\'emploi, qui permettent aux utilisateurs de créer rapidement des applications et des sites web sans avoir à tout développer à partir de zéro.',
        time_saving_point2: 'Les utilisateurs peuvent expérimenter rapidement des idées et des concepts, ce qui peut accélérer le processus d\'innovation.',
        time_saving_point3: 'Les outils no-code permettent également une livraison plus rapide de projets, car il y a moins de dépendance à des équipes de développement tierces.',
        cost_reduction_point1: 'Les outils no-code sont souvent abordables ou même gratuits, ce qui peut réduire considérablement les coûts de développement pour les entreprises et les particuliers.',
        cost_reduction_point2: 'Les entreprises n\'ont pas besoin d\'embaucher des développeurs pour créer des outils internes, ce qui peut réduire les coûts de développement à long terme.',
        flexibility_point1: 'Les outils no-code sont souvent modulaires et faciles à adapter, ce qui permet aux utilisateurs de créer des solutions personnalisées pour répondre à leurs besoins spécifiques.',
        flexibility_point2: 'Les outils no-code peuvent être utilisés pour créer une variété de solutions, y compris des applications mobiles, des sites web, des chatbots, des formulaires en ligne et bien plus encore.',
        flexibility_point3: 'Les outils no-code permettent aux utilisateurs de modifier facilement leurs solutions en temps réel, sans avoir besoin de l\'aide d\'un développeur tiers.',
        scalability_point1: 'Les applications et les sites web créés avec des outils no-code peuvent être facilement mis à l\'échelle pour répondre aux besoins d\'une entreprise en croissance.',
        scalability_point2: 'Les utilisateurs peuvent ajouter des fonctionnalités supplémentaires à leur solution no-code pour répondre aux besoins en constante évolution de leur entreprise ou de leur projet.',
        integrations_description: 'Les outils de no-code sont souvent conçus pour se connecter facilement à d\'autres applications et services, permettant ainsi une intégration fluide dans les flux de travail existants. Les intégrations permettent aux entreprises de rationaliser leurs processus, d\'augmenter leur efficacité et de réduire les erreurs humaines.',
        automation_description: 'Les outils de no-code permettent de réaliser des automatisations de processus complexes, sans nécessiter de connaissances en programmation. Cela permet aux entreprises de gagner du temps et de l\'argent en automatisant des tâches répétitives et chronophages. Les automatisations peuvent être utilisées pour des processus internes tels que la gestion des ressources humaines, ou pour des tâches externes telles que l\'envoi d\'emails personnalisés à des clients.',
        market_speed_point1: 'Les outils no-code sont souvent dotés de fonctionnalités prêtes à l\'emploi, qui permettent aux utilisateurs de créer rapidement des applications et des sites web sans avoir à tout développer à partir de zéro.',
        market_speed_point2: 'Les utilisateurs peuvent expérimenter rapidement des idées et des concepts, ce qui peut accélérer le processus d\'innovation.',
        market_speed_point3: 'Les outils no-code permettent également une livraison plus rapide de projets, car il y a moins de dépendance à des équipes de développement tierces.',
        no_maintenance_description: 'L\'un des avantages clés du no-code est qu\'il élimine le besoin de maintenance continue. Les outils de no-code sont souvent gérés par des fournisseurs tiers, ce qui signifie qu\'ils prennent en charge les mises à jour, les correctifs de sécurité et la maintenance du logiciel. Cela permet aux entreprises de se concentrer sur leur activité principale plutôt que sur la gestion de leur infrastructure informatique.',
        target_audience_title: 'En résumé, le no-code est pour quel public ?',
        target_audience_description: 'Le no-code est destiné à tous ceux qui souhaitent créer des applications et automatiser leurs processus sans avoir à écrire de code, qu\'ils soient entrepreneurs, professionnels de l\'informatique, employés de bureau, travailleurs indépendants ou étudiants. Les outils de no-code sont conçus pour être faciles à utiliser et à comprendre, même pour les personnes sans connaissances techniques, ce qui les rend accessibles à un large public. Le no-code offre ainsi la possibilité à chacun de devenir créatif et de créer des solutions innovantes pour répondre à ses besoins spécifiques, sans avoir à dépendre d\'une équipe de développeurs.',
        custom_work_software_title: 'Votre logiciel de travail sur mesure',
        free_trial_description: 'Essai gratuit 15 jours - Aucune carte de crédit requise',
        get_started: 'J\'y vais',


        // contact
        contact_title: 'Contact',
        commercial_service: 'Service commercial',
        by_phone: 'Par téléphone',
        technical_support: 'Support technique',
        technical_support_description: 'Le support technique est un service offert pour aider les clients à résoudre des problèmes techniques liés à un produit ou un service.',
        by_email: 'Par email',
        support_assistance: 'Support assistance',
        support_assistance_description: 'Le support assistance est un service destiné à aider les clients à obtenir des informations sur les produits ou services, ainsi qu\'à les accompagner dans la conception ou le développement de ces produits ou services.',
        open_ticket: 'Ouvrir un ticket',

        support_standard: 'Support Standard',
        support_standard_description: 'Nous mettons votre autonomie au centre de nos préoccupations',
        discover: 'Découvrir',
        support_premium: 'Support Premium',
        support_premium_description: 'Nous mettons tout en œuvre pour vous offrir une expérience optimale',
        support_business: 'Support Business',
        support_business_description: 'Nous nous engageons à faciliter vos déclarations et le suivi d\'incident',
        support_enterprise: 'Support Entreprise',
        support_enterprise_description: 'Nous nous engageons à être toujours au rendez-vous de votre satisfaction',
        custom_work_software: 'Votre logiciel de travail sur mesure',
        free_trial_15_days: 'Essai gratuit 15 jours - Aucune carte de crédit requise',
        go: 'J\'y vais',


        // support
        how_can_we_help: 'Bonjour, comment pouvons-nous vous aider ?',
        api_documentation: 'Documentation API',
        use_api_configure_services: 'Utilisez l\'API pour configurer les services.',
        video_tutorials: 'Tutoriels vidéo',
        train_become_autonomous_platform: 'Formez-vous et devenez autonome sur la plateforme.',
        support_ticket: 'Ticket de support',
        report_track_incident_consult_history: 'Déclarez et suivez un incident, et consultez l\'historique.',
        frequently_asked_questions: 'Questions les plus fréquentes',
        how_does_platform_work: 'Comment fonctionne votre plateforme ?',
        platform_works_description: 'Notre plateforme est conçue pour être simple et intuitive à utiliser. Vous pouvez commencer par choisir un modèle prédéfini, puis personnaliser les éléments en fonction de vos besoins. Vous pouvez également ajouter des fonctionnalités en utilisant des blocs de construction préconçus ou en créant vos propres composants.',
        do_i_need_to_code_to_use_platform: 'Est-ce que je dois savoir coder pour utiliser votre plateforme ?',
        no_coding_needed_description: 'Non, vous n\'avez pas besoin de connaissances en programmation pour utiliser notre plateforme no-code. Elle est conçue pour être accessible aux utilisateurs de tous niveaux de compétence technique. Si vous avez des questions ou besoin d\'aide, notre équipe de support est là pour vous assister.',
        what_are_the_pricing_plans: 'Quels sont les tarifs de votre plateforme ?',
        pricing_plans_description: 'Nous offrons une variété de plans tarifaires pour répondre aux besoins de nos clients. Nos tarifs commencent à partir de 8 euros par mois/licence pour les petites entreprises et les particuliers, et varient en fonction du nombre de projets et des fonctionnalités utilisées. Consultez notre rubrique "Prix" pour en savoir plus et choisir le plan le plus adapté à vos besoins.',
        how_can_i_contact_support_team: 'Comment puis-je contacter votre équipe de support si j\'ai des questions ?',
        contact_support_description: 'Si vous avez des questions sur le fonctionnement de notre plateforme, vous pouvez contacter notre équipe de support par téléphone, par email ou via le chat en direct. Nous sommes là pour vous aider à tout moment et nous nous engageons à résoudre rapidement tous les problèmes que vous pourriez rencontrer.',
        how_can_i_integrate_third_party_services: 'Comment puis-je intégrer des services tiers à mon application ?',
        integration_with_third_party_services_description: 'Notre plateforme est conçue pour s\'intégrer facilement à une variété de services tiers. Nous proposons un large choix d\'intégrations préconfigurées, y compris avec des services populaires comme Stripe, Zapier, Aircall, et bien d\'autres. Si vous avez besoin d\'une intégration personnalisée, notre équipe peut vous accompagner dans sa mise en place.',
        how_can_i_ensure_security_of_my_application: 'Comment puis-je assurer la sécurité de mon application ?',
        application_security_description: 'Notre plateforme intègre plusieurs fonctionnalités de sécurité pour protéger vos données et votre application. Nous utilisons des protocoles avancés comme HTTPS, SSL et OAuth. Nous réalisons également des sauvegardes régulières pour garantir la sécurité de vos informations en cas d\'incident.',
        how_can_i_get_feature_updates_for_my_platform: 'Comment puis-je obtenir des mises à jour de fonctionnalités pour ma plateforme ?',
        feature_updates_description: 'Nous publions régulièrement des mises à jour pour améliorer l\'expérience utilisateur et ajouter de nouvelles fonctionnalités. Vous pouvez recevoir des notifications par email ou consulter notre site pour découvrir les dernières nouveautés. Si vous avez des suggestions, n\'hésitez pas à nous les partager.',

        // services
        getYourAccess: 'Obtenez dès à présent vos accès !',
        servicess: 'Services',

        // documents
        documentsTitle: 'Documents',
        documentsDescription: 'Service Document - Édition, stockage et personnalisation de vos documents',
        documentsDescriptionPlus: "" +
            " <p>Notre service Document révolutionne la façon dont vous gérez vos documents. Il ne s'agit pas seulement d'un outil d'édition et de stockage. C'est une plateforme conçue pour vous aider à créer des documents sur mesure grâce à l'utilisation de balises, et ce dans plusieurs formats de documents, tels que .docx, .pdf et .xlsx.</p>\n" +
            "  <h2>Édition et stockage des documents</h2>\n" +
            "  <p>Avec notre service Document, éditez et stockez vos documents en toute simplicité. Que vous souhaitiez créer un nouveau document ou stocker un document existant, notre service est là pour vous aider.</p>\n" +
            "  <h2>Création de templates de documents</h2>\n" +
            "  <p>Notre service vous permet de créer des templates de documents. Que vous ayez besoin d'un modèle pour un contrat, une facture ou un rapport, nos outils d'édition vous permettront de créer des documents professionnels avec facilité.</p>\n" +
            "  <h2>Utilisation de balises</h2>\n" +
            "  <p>En intégrant des balises dans vos templates, vous pouvez créer des documents à remplir avec des données prélevées. Que vous souhaitiez intégrer le nom d'un client, une date ou toute autre information, nos balises rendent la personnalisation simple et efficace.</p>\n" +
            "  <h2>Support de plusieurs formats de documents</h2>\n" +
            "  <p>Notre service Document prend en charge plusieurs formats, y compris .docx, .pdf et .xlsx. Quel que soit le type de document que vous souhaitez créer ou stocker, notre service peut répondre à vos besoins.</p>\n" +
            "  <h2>Le service Document</h2>\n" +
            "  <p>Le service Document est bien plus qu'un simple outil d'édition et de stockage. C'est votre partenaire pour créer des documents personnalisés et professionnels. Découvrez une nouvelle manière de gérer vos documents.</p>\n",

        // sms
        smsTitle: 'SMS',
        smsDescription: 'Service SMS - Communication rapide et efficace',
        smsDescriptionPlus: "" +
            "<p>Notre service SMS transforme la façon dont vous gérez vos communications. Il ne se limite pas à l'envoi de SMS, c'est une plateforme conçue pour automatiser vos envois, créer des modèles personnalisés grâce à l'utilisation de balises, et consulter les historiques d'envoi.</p>\n" +
            "  <h2>Envoyer des SMS via des fournisseurs externes</h2>\n" +
            "  <p>Avec notre service SMS, vous pouvez envoyer des messages via des fournisseurs externes comme AllMySms. Que ce soit pour un client ou une équipe entière, notre service vous accompagne.</p>\n" +
            "  <h2>Création de templates de SMS</h2>\n" +
            "  <p>Créez des modèles adaptés à vos besoins : rappels de rendez-vous, annonces spéciales... Nos outils facilitent la création rapide et professionnelle.</p>\n" +
            "  <h2>Utilisation de balises</h2>\n" +
            "  <p>Insérez automatiquement le nom d’un client, une date, ou autre donnée grâce à nos balises personnalisables.</p>\n" +
            "  <h2>Automatisation des envois</h2>\n" +
            "  <p>Planifiez et automatisez vos campagnes de SMS selon vos besoins, pour gagner un temps précieux.</p>\n" +
            "  <h2>Consultation des historiques d'envoi</h2>\n" +
            "  <p>Gardez une trace complète de toutes vos communications SMS avec un historique consultable à tout moment.</p>\n" +
            "  <h2>Le service SMS</h2>\n" +
            "  <p>Bien plus qu’un outil de messagerie, c’est votre allié pour une communication rapide, personnalisée et efficace.</p>\n",

        //Calendrier
        calendarTitle: 'Calendrier',
        calendarDescription: 'Un calendrier plein de surprises',
        calendarDescriptionPlus: "" +
            "<p>Notre service Calendrier vous permet de gérer facilement vos événements et rendez-vous. Que ce soit pour des réunions, des rendez-vous clients ou des événements d'équipe, vous disposez d'un outil centralisé pour organiser et planifier vos activités.</p>\n" +
            "  <h2>Fonctionnalités avancées de planification</h2>\n" +
            "  <p>Notre service Calendrier offre une multitude de fonctionnalités avancées pour optimiser vos plannings. Vous pouvez bénéficier de vues personnalisables, de rappels, de notifications, de la gestion des disponibilités, ainsi que de la possibilité de partager des calendriers avec d'autres utilisateurs.</p>\n" +
            "  <h2>Intégration avec le service MAP</h2>\n" +
            "  <p>Le service Calendrier est étroitement lié au service MAP, ce qui vous permet d'afficher les distances entre les rendez-vous et d'optimiser vos déplacements. Vous pouvez visualiser les itinéraires entre différents points, ce qui vous aide à prendre des décisions éclairées et à maximiser votre efficacité.</p>\n" +
            "  <h2>Synchronisation avec d'autres agendas</h2>\n" +
            "  <p>Notre service Calendrier offre également la possibilité de synchroniser vos événements avec d'autres agendas, tels que Google Agenda. Cela vous permet de centraliser vos activités et de mieux gérer votre emploi du temps.</p>\n" +
            "  <h2>Le service Calendrier</h2>\n" +
            "  <p>Le service Calendrier est bien plus qu'un simple outil de gestion du temps. C'est votre partenaire pour une planification avancée, une gestion optimisée des rendez-vous et une synchronisation fluide avec vos autres agendas. Découvrez comment notre service peut transformer votre quotidien.</p>\n",

// Comptabilité
        accountingTitle: 'Comptabilité',
        accountingDescription: 'Service comptabilité - Gestion facilitée des devis, factures et avoirs',
        accountingDescriptionPlus: "" +
            "<p>Notre service comptabilité vous offre une solution complète pour gérer efficacement vos devis, factures et avoirs. Grâce à notre interface intuitive, vous pouvez éditer facilement des documents professionnels, choisir parmi une sélection de templates visuels ou créer vos propres modèles personnalisés. Nous mettons à votre disposition une application et des widgets pour faciliter l'accès aux informations pertinentes. De plus, notre service permet de connecter vos moyens de paiement tels que Stripe pour simplifier la gestion des règlements.</p>\n" +
            "  <h2>Édition de devis, factures et avoirs</h2>\n" +
            "  <p>Avec notre service comptabilité, créez et gérez vos documents financiers rapidement et efficacement. Qu’il s’agisse d’un devis, d’une facture ou d’un avoir, toutes les opérations sont centralisées.</p>\n" +
            "  <h2>Templates visuels ou création de modèles personnalisés</h2>\n" +
            "  <p>Choisissez parmi des modèles prêts à l'emploi ou concevez les vôtres. Notre éditeur vous permet de personnaliser l’apparence et le contenu pour s’adapter à votre activité.</p>\n" +
            "  <h2>Application et widgets pour une facilité d'accès</h2>\n" +
            "  <p>Accédez à vos documents comptables à tout moment via notre application ou nos widgets, que vous soyez au bureau ou en déplacement.</p>\n" +
            "  <h2>Connexion de moyens de paiement tels que Stripe</h2>\n" +
            "  <p>Intégrez vos outils de paiement pour automatiser l'envoi et le suivi de règlements. Gagnez du temps et sécurisez vos transactions.</p>\n" +
            "  <h2>Le service comptabilité</h2>\n" +
            "  <p>Bien plus qu’un logiciel de facturation, notre service comptabilité vous aide à centraliser vos opérations financières avec efficacité.</p>\n",

// Formulaire
        formTitle: 'Formulaire',
        formDescription: 'Service formulaire - Facilitateur d\'expériences et de connexions',
        formDescriptionPlus: "" +
            " <p>Avec notre service Formulaire, repensez la manière dont vous gérez vos procédures et l’expérience client. Plus qu’un simple outil de création de formulaires, c’est une plateforme intelligente qui simplifie les liaisons entre les données et leur structure.</p>\n" +
            "  <h2>Création de formulaires faciles à intégrer</h2>\n" +
            "  <p>Générez des formulaires à intégrer dans vos parcours client ou processus internes, de façon intuitive et rapide.</p>\n" +
            "  <h2>Divers types de format</h2>\n" +
            "  <p>Notre outil prend en charge plusieurs types de champs : texte, nombre, date, sélection, etc. pour répondre à tous vos besoins.</p>\n" +
            "  <h2>Gestion des fonctions et des formules</h2>\n" +
            "  <p>Ajoutez des règles de calcul, des conditions dynamiques et des formules personnalisées directement dans vos formulaires.</p>\n" +
            "  <h2>Partage de templates sur notre espace partagé</h2>\n" +
            "  <p>Partagez vos formulaires avec d'autres utilisateurs via notre espace de modèles partagés. Encouragez l'inspiration et la collaboration.</p>\n" +
            "  <h2>Le service formulaire</h2>\n" +
            "  <p>Notre service est une solution complète pour créer, partager et exploiter intelligemment des formulaires professionnels.</p>\n",

// Produits
        productsTitle: 'Produits',
        productsDescription: 'Gestion de vos catalogues produits',
        productsDescriptionPlus: "" +
            "<p>Avec notre service Produits, réinventez la gestion de vos catalogues. Plus qu'un gestionnaire de fiches produit, c'est une plateforme complète pensée pour maximiser efficacité et souplesse.</p>\n" +
            "<h2>Gestion des catégories de produits</h2>\n" +
            "<p>Organisez vos articles par catégories, facilitez la navigation et améliorez l'expérience utilisateur de vos clients ou équipes.</p>\n" +
            "<h2>Paramétrage avancé des produits</h2>\n" +
            "<p>Ajoutez des grilles tarifaires, des attributs dynamiques, des visuels, des documents associés, ou encore gérez les taxes avec une grande précision.</p>\n" +
            "<h2>Le service produits</h2>\n" +
            "<p>Notre service va bien au-delà d’une simple base de données produits : il structure vos offres pour plus d’agilité, d’impact et de personnalisation.</p>\n",

// Email
        emailDescription: 'Service email - Communication simplifiée et optimisée',
        emailDescriptionPlus:
            '  <p>Avec notre service Email, optimisez la gestion de vos communications électroniques. C’est une plateforme conçue pour améliorer votre productivité et fluidifier vos échanges.</p>\n' +
            '  <h2>Envoyez et recevez des emails avec facilité</h2>\n' +
            '  <p>Gérez vos emails professionnels dans un espace centralisé, que ce soit pour de simples messages ou des échanges complexes.</p>\n' +
            '  <h2>Créez des templates sur mesure</h2>\n' +
            '  <p>Utilisez notre éditeur riche ou en glisser-déposer pour concevoir rapidement des modèles d’emails performants et élégants.</p>\n' +
            '  <h2>Automatisation des envois</h2>\n' +
            '  <p>Planifiez des envois automatiques selon vos critères : réponses, relances, newsletters... tout devient plus rapide.</p>\n' +
            '  <h2>Consultez les historiques dans tous les contextes</h2>\n' +
            '  <p>Gardez une trace complète de vos emails, dans tous les cas d’usage, pour ne rien perdre de vos interactions importantes.</p>\n' +
            '  <h2>Le service email</h2>\n' +
            '  <p>Le service Email est votre assistant de communication moderne : fiable, puissant et intégré à tous vos outils métiers.</p>\n',

        //
// Leads
        leadsDescription: 'Service leads - Gestion optimisée des prospects',
        leadsDescriptionPlus:
            '  <p>Notre service leads transforme la manière dont vous gérez vos prospects. Au-delà d\'un simple outil de gestion, notre système est une solution complète conçue pour vous aider à maximiser vos efforts de conversion.</p>\n' +
            '  <h2>Espaces aménagés pour la gestion des leads</h2>\n' +
            '  <p>Nous savons que chaque interaction avec un prospect est une opportunité à ne pas manquer. C\'est pourquoi notre service leads vous permet de créer des espaces aménagés spécifiquement pour la gestion des leads. Ces espaces sont conçus pour vous aider à suivre chaque prospect de manière efficace, à gérer les interactions, à planifier les suivis et à convertir les prospects en clients.</p>\n' +
            '  <h2>Gestion de multiples espaces de leads</h2>\n' +
            '  <p>Que vous dirigiez une entreprise à multiples facettes ou que vous travailliez avec différentes campagnes marketing, la possibilité de gérer plusieurs espaces de leads est essentielle. Notre système vous offre cette flexibilité. Vous pouvez configurer et gérer autant d\'espaces de leads que nécessaire, chacun étant adapté à une activité ou une campagne spécifique. Cela vous permet de suivre et de gérer vos prospects de manière organisée et ciblée.</p>\n' +
            '  <h2>Expérimentez l\'évolution de la gestion des prospects</h2>\n' +
            '  <p>Avec notre service leads, la gestion des prospects n\'est plus une tâche fastidieuse. C\'est un processus optimisé qui vous aide à atteindre vos objectifs de conversion plus rapidement et plus efficacement.</p>',

//
// Signature électronique
        esignDescription: 'Service signature électronique - Simplifiez vos processus de signature',
        esignDescriptionPlus: '' +
            ' <p>Avec notre service de signature électronique, transformez la façon dont vous gérez vos processus de signature. Au-delà d\'un simple outil d\'envoi de demandes de signature, c\'est une solution complète conçue pour optimiser et sécuriser vos transactions.</p>\n' +
            '  <h2>Envoi de signatures par email ou SMS</h2>\n' +
            '  <p>Notre service permet l\'envoi de demandes de signature par email ou SMS. Quel que soit le canal préféré de vos clients, notre système offre la flexibilité nécessaire pour atteindre efficacement vos destinataires.</p>\n' +
            '  <h2>Suivi des événements de signature</h2>\n' +
            '  <p>Une fois la demande envoyée, notre service vous permet de suivre chaque étape : signature réalisée, refusée, en attente. Vous gardez ainsi une visibilité complète sur l\'état de vos documents.</p>\n' +
            '  <h2>Intégration avec diverses applications</h2>\n' +
            '  <p>Selon le plugin utilisé, notre service peut s\'intégrer à différentes applications pour fluidifier vos processus de signature au sein de vos outils métiers.</p>\n' +
            '  <h2>Le service signature électronique</h2>\n' +
            '  <p>Plus qu\'un simple outil, notre service de signature électronique est un véritable levier de fluidité et de sécurité dans vos processus administratifs.</p>\n',

//
// MAP
        mapDescription: 'Service MAP - Gestion de localisation et planification optimisée',
        mapDescriptionPlus: "" +
            " <p>Avec notre service MAP, réinventez la manière dont vous gérez la localisation et la planification de vos ressources. Au-delà d'un simple outil de visualisation sur carte, c'est une solution robuste qui facilite la gestion des objets ou enregistrements géolocalisés.</p>\n" +
            "  <h2>Gestion des objets ou enregistrements sur carte</h2>\n" +
            "  <p>Notre service vous permet de gérer facilement les objets ou enregistrements via une vue cartographique. Que vous ayez besoin de suivre un colis ou de piloter une flotte de véhicules, la visualisation devient intuitive.</p>\n" +
            "  <h2>Calcul de distances</h2>\n" +
            "  <p>Notre système calcule les distances entre deux points : utile pour planifier des livraisons, tracer un itinéraire ou organiser un road trip.</p>\n" +
            "  <h2>Amélioration de la planification</h2>\n" +
            "  <p>En intégrant MAP à votre calendrier, vous améliorez vos plannings : coordination d'équipes terrain, itinéraires optimisés... tout devient plus fluide.</p>\n" +
            "  <h2>Le service MAP</h2>\n" +
            "  <p>Le service MAP est bien plus qu'une carte. C'est votre outil stratégique de géolocalisation pour une organisation plus efficace.</p>\n",

//
// Téléphonie IP
        telephonyDescription: 'Service téléphonie IP - Connectivité et accès aux appels simplifiés',
        telephonyDescriptionPlus: "" +
            " <p>Avec notre service de téléphonie IP, révolutionnez la gestion de vos communications. Il ne s'agit pas d'un simple service VoIP, mais d'une plateforme intégrée à vos outils métiers.</p>\n" +
            "  <h2>Connexion avec votre fournisseur de téléphonie IP</h2>\n" +
            "  <p>Grâce à nos plugins, connectez aisément votre opérateur téléphonique. Gérez vos appels professionnels avec facilité.</p>\n" +
            "  <h2>Historiques d'appels accessibles partout</h2>\n" +
            "  <p>Consultez vos historiques d'appels en toute mobilité : au bureau, à distance ou en déplacement.</p>\n" +
            "  <h2>Click to call</h2>\n" +
            "  <p>Passez des appels d'un simple clic sur vos fiches contact. Plus rapide, plus pratique, moins d'erreurs.</p>\n" +
            "  <h2>Téléchargement des enregistrements audio des appels</h2>\n" +
            "  <p>Enregistrez et archivez vos conversations téléphoniques importantes pour les réécouter à tout moment.</p>\n" +
            "  <h2>Le service téléphonie IP</h2>\n" +
            "  <p>Votre assistant télécom connecté : simple, efficace, mobile. Pour une gestion téléphonique moderne.</p>\n",

//
// Contact & compagnie
        contactCompanyDescription: 'Service contact et compagnie - Centralisation des informations et optimisation des vues',
        contactCompanyDescriptionPlus: "" +
            "<p>Avec notre service contact et compagnie, repensez la gestion des contacts et entreprises. Ce n’est pas qu’un CRM, mais une plateforme conçue pour centraliser toutes vos données autour d’une entité.</p>\n" +
            "  <h2>Focus sur les procédures</h2>\n" +
            "  <p>Suivez chaque contact ou entreprise comme une procédure complète. Centralisez tout : historique, documents, tâches, etc.</p>\n" +
            "  <h2>Vue à 360 degrés</h2>\n" +
            "  <p>Profitez d’une vue complète sur chaque entité : interactions, documents, échanges, événements... tout est visible d’un coup d'œil.</p>\n" +
            "  <h2>Gestion des zones</h2>\n" +
            "  <p>Séparez vos données par zones ou structures : utile pour gérer plusieurs entreprises ou départements sans confusion.</p>\n" +
            "  <h2>Le service contact et compagnie</h2>\n" +
            "  <p>Bien plus qu’un répertoire, c’est une solution complète de gestion centralisée. Pour une vision claire, rapide et partagée de vos interlocuteurs.</p>\n",

// pricing
        EnsembleProductivite: "Ensemble, augmentons votre productivité",
        CommencezGratuitement: "Commencez gratuitement",
        PeriodeEssai: "Période d'essai de 15 jours - Aucune carte de crédit requise",
        annual: 'Annuel',
        monthly: 'Mensuel',
        productivity: 'Ensemble, faisons grimper votre productivité',
        freeStart: 'Commencez gratuitement',
        trialPeriod: 'Période d\'essai de 15 jours - Aucune carte de crédit requise',
        solo: 'Gratuit',
        price: '0€',
        freeLifetime: 'Gratuit à vie',
        individualPlanIncludes: 'Le forfait Gratuit comprend :',
        collaborativeWorkspace: 'Espace de travail collaboratif',
        freeAPIIntegration: 'Intégration d\'API gratuite',
        limitedTimeHistory: 'Historique limité dans le temps (7 jours)',
        powerfulFormulas: 'Formules et automatisations puissantes',

        basic: 'Basic',
        perUserMonth: 'utilisateur / mois',
        includesIndividualPlus: 'Inclut le forfait Solo, plus :',
        priorityCustomerService: 'Service client prioritaire',
        fileStorage: '5 Go de stockage de fichiers',
        limitedTimeHistory30: 'Historique limité dans le temps (30 jours)',
        unlimitedItems: 'Éléments illimités',
        basicPermissionManagement: 'Gestion des permissions basique',
        recommended: 'Recommandé',

        standard: 'Plus',
        includesBasicPlus: 'Inclut le forfait Gratuit, plus :',
        calendarView: 'Vue calendrier',
        unlimitedAutomations: 'Automatisations illimitées',
        limitedTimeHistory7: 'Historique limité dans le temps (90 jours)',
        VIPServiceManagement: 'Service de gestion VIP',
        permissionManagementAllLevels: 'Gestion des permissions à tous les niveaux',

        pro: 'Pro',
        includesStandardPlus: 'Inclut le forfait Standard, plus :',
        documentVisualizationSAS: 'SAS de visualisation des documents (via un QR code)',
        fileStorage15: '15 Go de stockage de fichiers',
        premiumSupport: 'Assistance premium',
        createDashboard: 'Création d\'un tableau de bord combinant jusqu\'à 50 tableaux',
        enterprise: 'Entreprise',
        custom: 'Sur mesure',
        includesProPlus: 'Inclut le forfait Pro, plus :',
        unlimitedPlugins: 'Plugins illimités',
        unlimitedFileStorage: 'Stockage de fichiers illimité',
        unlimitedSetupIntegration: 'Intégrations illimitées',
        unlimitedTimeHistory: 'Historique illimité dans le temps',
        onDemandDevelopment: 'Développement à la demande',
        note: 'À noter',
        someAppsNote: 'Certaines applications ou certains plugins proposent des forfaits indépendants. Pour plus d\'informations sur les tarifs de ces applications, cliquez',
        here: 'ici',
        customWorkSoftware: 'Votre logiciel de travail sur mesure',
        freeTrialNote: 'Essai gratuit 15 jours - Aucune carte de crédit requise',
        goThere: "J'y vais",

        // views

        advantages_of_kanban: 'Les avantages de la gestion sur une vue Kanban',
        task_visualization: 'Visualisation des tâches',
        task_visualization_description: 'La vue Kanban permet de visualiser facilement toutes les tâches d\'un projet en un coup d\'œil. Les tâches sont représentées sous forme de cartes que l\'on peut déplacer d\'une colonne à l\'autre pour indiquer leur état d\'avancement. Cette visualisation claire et concise permet de mieux comprendre le projet dans son ensemble et de mieux coordonner les efforts de l\'équipe.',
        productivity_optimization: 'Optimisation de la productivité',
        productivity_optimization_description: 'La vue Kanban permet d\'optimiser la productivité en identifiant rapidement les tâches bloquées ou nécessitant une attention particulière. Les membres de l\'équipe peuvent ainsi réagir rapidement pour résoudre les problèmes et maintenir un rythme de travail soutenu. De plus, en réduisant le temps nécessaire pour suivre l\'avancement, ils peuvent se concentrer davantage sur la réalisation des tâches.',
        theFlexibility: 'Flexibilité',
        flexibility_description: 'La vue Kanban permet de modifier facilement l\'ordre des tâches selon leur priorité ou leur urgence. Les cartes peuvent être déplacées simplement d\'une colonne à l\'autre, ce qui facilite l\'adaptation aux changements. Cette flexibilité est précieuse dans un environnement de travail dynamique.',
        drag_and_drop: 'Glisser-déposer',
        customizable_card: 'Carte personnalisable',
        datatable: 'Table de données',
        advantages_datatable: 'Voici les avantages de la gestion via une vue Table de données :',
        advantage_1: 'Tri et filtrage efficaces :',
        advantage_1_desc: 'La vue Table permet de trier et filtrer les données efficacement, facilitant la recherche d\'informations pertinentes. Elle permet également de combiner plusieurs filtres pour des résultats encore plus précis.',
        advantage_2: 'Pagination des données :',
        advantage_2_desc: 'La pagination divise les résultats en plusieurs pages, ne chargeant que les données nécessaires. Cela réduit les temps de chargement et améliore les performances de l\'application.',
        advantage_3: 'Personnalisation de l\'apparence :',
        advantage_3_desc: 'La vue Table permet de personnaliser facilement l\'apparence des données pour l\'adapter à votre marque ou à vos préférences. Couleurs, polices, tailles et disposition peuvent être ajustées.',
        feature_1: 'Glisser-déposer',
        feature_2: 'Carte personnalisable',
        advantages_calendar: 'Voici les avantages de la gestion via une vue Calendrier :',
        advantage_cal1: 'Vue d\'ensemble :',
        advantage_cal1_desc: 'La vue Calendrier offre une vue d\'ensemble des tâches, rendez-vous ou événements à venir. Cela permet une meilleure planification du temps.',
        advantage_cal2: 'Planification simplifiée :',
        advantage_cal2_desc: 'Les éléments peuvent être facilement planifiés en les glissant-déposant sur la date et l\'heure souhaitées, ce qui fait gagner du temps.',
        advantage_cal3: 'Partage et collaboration :',
        advantage_cal3_desc: 'La vue Calendrier peut être partagée avec d\'autres membres de l\'équipe ou des partenaires externes, favorisant la coordination.',
        imageURL: 'https://www.objectif-crm.com/assets/images/vueCalendrier.PNG',
        be_ready: 'SOYEZ PRÊT À EN AVOIR PLUS',
        get_your_access: 'Obtenez dès à présent vos accès !',
        start_trial: 'Démarrer l\'essai',
        request_callback: 'Demande de rappel',


        // integration

        ACtitle: 'La téléphonie d\'entreprise connectée à vos outils',
        integrationBenefits: 'Intégrer Aircall peut avoir de nombreux avantages pour votre entreprise. Voici quelques raisons d\'envisager son intégration :',
        customerExperience: '<strong>Amélioration de l\'expérience client :</strong> Grâce à Aircall, accédez rapidement à l\'historique des commandes, aux préférences et aux interactions de chaque client pour leur offrir un service plus personnalisé.',
        taskAutomation: '<strong>Automatisation des tâches :</strong> Aircall permet d\'automatiser des tâches comme la création de contacts ou la mise à jour de dossiers, vous faisant gagner du temps.',
        performanceTracking: '<strong>Suivi des performances :</strong> Suivez en temps réel les appels, durées, taux de réponse ou de conversion afin d\'améliorer les performances de vos équipes.',
        enhancedCollaboration: '<strong>Collaboration améliorée :</strong> En centralisant les informations clients, Aircall facilite la coordination entre les équipes.',
        summary: 'En résumé, Aircall améliore l\'expérience client, automatise les tâches, suit les performances et facilite la collaboration.',

        RHtitle: 'Atteignez et engagez vos clients comme jamais auparavant',
        integrationBenefits2: 'Intégrer Ringover peut offrir plusieurs avantages pour votre entreprise, notamment :',
        costReduction: '<strong>Réduction des coûts :</strong> Des appels internationaux à tarif réduit et des conférences sans déplacements permettent de réduire les frais.',
        customerPersonalization: '<strong>Personnalisation de l\'expérience :</strong> Utilisez des scripts ou identifiez les clients VIP pour leur offrir un service plus adapté.',
        productivityImprovement: '<strong>Amélioration de la productivité :</strong> Accès direct aux informations clients pour se concentrer sur les tâches à forte valeur ajoutée.',
        summary2: 'Ringover permet de réduire les coûts, améliorer la productivité, personnaliser l\'expérience client et optimiser les performances de votre équipe.',

        MAPtitle: 'Tout sur la carte',
        integrationBenefits3: 'Intégrer Google Maps présente de nombreux atouts, notamment :',
        customerLocalization: '<strong>Localisation des clients :</strong> Visualisez facilement les emplacements clients et optimisez les visites.',
        travelOptimization: '<strong>Optimisation des déplacements :</strong> Planifiez efficacement les itinéraires de vos équipes grâce aux trajets Google Maps.',
        dataAccuracy: '<strong>Amélioration de la précision des données :</strong> Ajoutez des infos de géolocalisation pour mieux segmenter vos clients.',
        dataVisualization: '<strong>Visualisation des données :</strong> Observez vos zones d\'activité et vos points à fort potentiel sur la carte.',
        customerExperience3: '<strong>Expérience client enrichie :</strong> Fournissez des infos de proximité, itinéraires ou suggestions en lien avec leur position.',
        summary3: 'Google Maps aide à localiser, optimiser les trajets, personnaliser les interactions et visualiser vos zones de développement.',

        SPtitle: 'Une infrastructure financière adaptée à Internet',
        integrationBenefits4: 'Intégrer Stripe peut grandement simplifier la gestion des paiements :',
        paymentFacilitation: '<strong>Facilitation des paiements :</strong> Proposez un paiement direct depuis les factures ou comptes clients.',
        paymentAutomation: '<strong>Automatisation des processus :</strong> Rappels automatiques, facturation récurrente, tout est simplifié.',
        paymentSecurity: '<strong>Sécurité :</strong> Stripe assure un haut niveau de sécurité et inspire confiance à vos clients.',
        paymentTracking: '<strong>Suivi :</strong> Visualisez vos encaissements en temps réel et ajustez votre stratégie commerciale.',
        toolIntegration: '<strong>Intégration :</strong> Fonctionne avec vos autres outils : e-commerce, marketing, CRM.',
        summary4: 'Stripe fluidifie les paiements, renforce la sécurité, centralise les ventes et automatise les relances.',

        GDtitle: 'Stockage des documents',
        integrationBenefits5: 'Intégrer Google Drive peut offrir plusieurs avantages pour votre entreprise, notamment :',
        centralizedStorage: '<strong>Stockage centralisé :</strong> En intégrant Google Drive, vous pouvez centraliser tous les documents et fichiers liés à vos clients ou à vos projets au même endroit. Cela facilite la collaboration et la gestion de projets pour votre équipe.',
        quickAccess: '<strong>Accès rapide aux documents :</strong> L\'intégration de Google Drive vous permet d\'accéder rapidement aux documents et fichiers pertinents directement depuis votre CRM. Vous n\'avez plus besoin de chercher dans différents dossiers ou applications.',
        realTimeCollaboration: '<strong>Collaboration en temps réel :</strong> Google Drive permet une collaboration simultanée sur des documents. En l\'intégrant, vous facilitez les échanges entre membres de l\'équipe ou avec les clients.',
        dataBackupSecurity: '<strong>Sauvegarde et sécurité des données :</strong> Google Drive offre un stockage sécurisé et des sauvegardes en temps réel. Cela vous permet de garantir la sécurité des données clients ou projets.',
        toolIntegration5: '<strong>Intégration avec d\'autres outils :</strong> Google Drive s\'intègre facilement avec des outils tiers comme les messageries ou logiciels de gestion de projets. Cette intégration centralise vos données et fluidifie la collaboration.',
        summary5: 'En résumé, l\'intégration de Google Drive facilite le stockage, l\'accès, la collaboration en temps réel, la sécurité des données et l\'interopérabilité avec d\'autres outils. Elle améliore ainsi l\'efficacité de votre équipe et la satisfaction client.',

        YStitle: 'Signature électronique',
        integrationBenefits6: 'Intégrer Yousign peut offrir plusieurs avantages pour votre entreprise, notamment :',
        simplifiedContractManagement: '<strong>Simplification de la gestion des contrats :</strong> Grâce à Yousign, créez, signez et suivez vos contrats directement dans votre outil, sans étapes manuelles chronophages.',
        reducedSigningDelays: '<strong>Réduction des délais de signature :</strong> Yousign propose une solution rapide et sécurisée de signature électronique, ce qui accélère vos cycles de validation.',
        improvedProductivity: '<strong>Amélioration de la productivité :</strong> En accélérant les signatures et simplifiant les processus, Yousign augmente la productivité globale de votre entreprise.',
        documentSecurity: '<strong>Sécurisation des documents :</strong> La technologie de Yousign garantit l\'authenticité et l\'intégrité des documents signés, renforçant la confiance.',
        toolIntegration6: '<strong>Intégration avec d\'autres outils :</strong> Yousign s\'intègre avec vos autres plateformes (messageries, gestion de projet, etc.), facilitant la centralisation des données.',
        summary6: 'En résumé, Yousign simplifie la gestion contractuelle, réduit les délais, améliore la productivité et sécurise vos documents. Une intégration essentielle pour gagner en efficacité.',

        AMStitle: 'Envoi de SMS',
        integrationBenefits7: 'Intégrer AllMySms peut offrir plusieurs avantages pour votre entreprise, notamment :',
        improvedCommunication: '<strong>Amélioration de la communication avec les clients :</strong> Envoyez des SMS groupés pour vos promotions, rappels ou notifications directement depuis le CRM.',
        increasedOpenRate: '<strong>Augmentation du taux d\'ouverture :</strong> Les SMS ont un taux d\'ouverture très élevé, renforçant la visibilité de vos messages.',
        automatedSending: '<strong>Automatisation des envois :</strong> Planifiez et déclenchez des SMS selon des scénarios définis : rappels, confirmations, anniversaires, etc.',
        messagePersonalization: '<strong>Personnalisation des messages :</strong> Utilisez des variables dynamiques (nom, date, montant...) pour rendre vos messages plus impactants.',
        dataCentralization: '<strong>Centralisation des données :</strong> Suivez toutes les communications clients directement dans votre CRM pour une meilleure visibilité.',
        summary7: 'En résumé, AllMySms permet une communication client plus rapide, plus efficace, plus ciblée et mieux organisée grâce à la centralisation des échanges dans un seul outil.',

        DStitle: 'Signature électronique',
        integrationBenefits8: 'Intégrer DocuSign peut offrir plusieurs avantages pour votre entreprise, notamment :',
        electronicSignature8: '<strong>Signature électronique :</strong> Obtenez des signatures directement dans le CRM, réduisant les délais de traitement.',
        documentCentralization: '<strong>Centralisation des documents :</strong> Gérez l\'ensemble des documents clients depuis un seul endroit, pour un meilleur suivi.',
        processAutomation: '<strong>Automatisation des processus :</strong> Automatisez la signature et la gestion des documents pour gagner du temps et fluidifier vos process.',
        integrationWithOtherApps: '<strong>Intégration avec d\'autres applications :</strong> DocuSign fonctionne avec Salesforce, Microsoft Dynamics, SharePoint, etc.',
        dataSecurity: '<strong>Sécurité des données :</strong> Profitez des protections avancées pour sécuriser les données sensibles et respecter les normes en vigueur.',
        summary8: 'En résumé, DocuSign permet de signer plus vite, centraliser les documents, automatiser les tâches et sécuriser les données. Un atout de taille pour votre efficacité.',

        customSoftware: 'Votre logiciel de travail sur mesure',
        getStarted: 'J\'y vais',

        // Esign

        theFuture: "L'avenir",
        electronicSignatures: "La signature électronique : l'avenir des transactions commerciales",
        electronicSignatureDescription1: "La signature électronique est un outil puissant pour les entreprises cherchant à améliorer leurs processus de signature de documents.",
        electronicSignatureDescription2: "De plus en plus d'entreprises adoptent la signature électronique pour ces raisons, et il est devenu clair que c'est l'avenir des transactions commerciales. Nous sommes convaincus que la signature électronique est un outil essentiel pour aider les entreprises à rester compétitives sur le marché.",
        powerAndPerformance: "Puissance et performances...",
        electronicSignaturePower: "La signature électronique est un outil puissant pour les entreprises cherchant à améliorer leurs processus de signature de documents. En effet, la signature électronique permet de réduire les délais de traitement des documents, d'augmenter la sécurité des données et de faciliter la gestion des transactions commerciales.",
        electronicSignatureFuture: "De plus en plus d'entreprises adoptent la signature électronique pour ces raisons, et il est devenu évident qu'elle représente l'avenir des transactions commerciales. En tant que fournisseur, nous sommes convaincus que la signature électronique est un outil essentiel pour aider les entreprises à rester compétitives sur le marché.",
        Avantages: "Avantages",
        LesAvantagesSignatureElectronique: "Les avantages de l'intégration de la signature électronique",
        IntegrerSignatureElectroniqueDescription1: "Intégrer la signature électronique à votre outil de travail peut offrir plusieurs avantages à votre entreprise, notamment :",
        AvantageReductionDelais: "Réduction des délais de traitement des documents",
        AvantageAugmentationSecurite: "Augmentation de la sécurité des données",
        AvantageFacilitationGestion: "Facilitation de la gestion des transactions commerciales",
        AvantageAmeliorationExperience: "Amélioration de l'expérience client",
        AvantageOptimisationProcessus: "Optimisation des processus de signature de documents",
        IntegrerSignatureElectroniqueDescription2: "En intégrant la signature électronique à votre outil de travail, vous pouvez améliorer l'efficacité de vos processus de signature de documents, offrir une expérience client plus rapide et efficace, et garantir la sécurité des données liées aux transactions commerciales.",
        ContactezNousEnSavoirPlus: "Contactez-nous pour en savoir plus",
        ContactezNousDescription: "Si vous souhaitez en savoir plus sur notre solution de signature électronique, n'hésitez pas à nous contacter. Nous serons ravis de répondre à toutes vos questions et de vous aider à améliorer vos processus grâce à la signature électronique.",
        DemandeRappel: "Demande de rappel",
        SignatureElectronique: "La signature électronique",


        titleTesti: "Témoignages",

        Support: "Support",
        SupportStandard: "Support standard",
        SupportStandardDescription: "Le support standard est notre niveau d'assistance de base, inclus pour tous les utilisateurs. Notre équipe dédiée est disponible pour répondre à vos questions techniques par e-mail et via notre centre d'aide en ligne 24/7. Vous aurez également accès à une vaste bibliothèque de ressources en libre-service, comprenant des guides, des tutoriels vidéo et une FAQ détaillée.",
        SupportPremium: "Support premium",
        SupportPremiumDescription: "Le support premium va encore plus loin que le support standard. En plus de tous les avantages du support standard, vous bénéficierez, en tant qu'utilisateur premium, d'une réponse prioritaire à vos demandes d'assistance, garantissant ainsi une réponse rapide de notre équipe de support. Vous aurez également accès à des sessions de formation en direct et à une assistance par chat en temps réel.",
        SupportBusiness: "Support silver",
        SupportBusinessDescription: "Le support silver est conçu pour les organisations de taille moyenne qui ont besoin d'une assistance plus approfondie. En plus de tous les avantages du support premium, vous bénéficierez, en tant qu'utilisateur silver, d'un gestionnaire de compte dédié qui comprendra vos besoins spécifiques et travaillera avec vous pour optimiser l'utilisation de notre plateforme dans votre organisation. De plus, vous aurez accès à des formations personnalisées pour votre équipe et à une assistance téléphonique pendant les heures ouvrables.",
        SupportEnterprise: "Support gold",
        SupportEnterpriseDescription: "Le support gold est notre niveau de support le plus complet, conçu pour les grandes organisations qui ont besoin d'une assistance spécialisée. En plus de tous les avantages du support silver, vous bénéficierez, en tant qu'utilisateur gold, d'un temps de réponse garanti de deux heures pour les demandes d'assistance urgentes. De plus, vous aurez accès à une assistance téléphonique 24/7, à des audits de santé de l'application et à des sessions de planification stratégique avec notre équipe de direction. Votre réussite est notre priorité, et nous ferons tout notre possible pour vous aider à atteindre vos objectifs.",
        SupportRequest: "Demander du support",
        CreateTicket: "Créer un ticket",

        //CGU
        TermsOfUse: "Conditions générales d'utilisation",
        EffectiveDate: "En vigueur au 01/01/2025",
        TermsDescription0: "Les présentes conditions générales d'utilisation régissent l'utilisation de betool, la propriété intellectuelle de Technician Soft Solution, et commercialisé par Soft Group Technologies. Technician Soft Solution est le propriétaire exclusif de tous les droits de propriété intellectuelle relatifs à betool. SASU Soft Group Technologies SIREN 921 865 358, une société de droit français, est responsable de la commercialisation de betool. L'utilisation de betool est soumise à l'acceptation de ces conditions, qui incluent les modalités de licence accordées par Technician Soft Solution et les conditions de vente et de service fournies par Soft Group Technologies.",
        TermsDescription1: "Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site et des services par https://www.betool.fr et de définir les conditions d’accès et d’utilisation des services par « l'Utilisateur ».",
        TermsDescription2: "Les présentes CGU sont accessibles sur le site à la rubrique « CGU ».",
        TermsDescription3: "Toute inscription ou utilisation du site implique l'acceptation sans aucune réserve ni restriction des présentes CGU par l’utilisateur. Lors de l'inscription sur le site via le formulaire d’inscription, chaque utilisateur accepte expressément les présentes CGU en cochant la case précédant le texte suivant : « Je reconnais avoir lu et compris les CGU et je les accepte ». En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se doit de renoncer à l'accès aux services proposés par le site. www.betool.fr se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU.",
        TermsDescription4: "Le site https://www.betool.fr se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU.",

        IntroductionTitle: "1. Introduction",
        IntroductionDescription: "Bienvenue sur notre plateforme SaaS de type WorkSpace, Work System, Work OS, CRM, ERP, Gestion de projet et Ticketing Solution. En tant que fournisseur de logiciels, nous proposons des services de licences utilisateurs, de licences d'applications, ainsi que des services d'accompagnement au paramétrage d'éléments divers tels que l'architecture, les documents, les templates, les API, etc. Notre solution est entièrement no-code, permettant à nos utilisateurs de créer des applications personnalisées sans avoir à écrire une seule ligne de code.",
        IntroductionTerms: "Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet de définir les modalités d'utilisation de notre plateforme et des services que nous proposons.",
        IntroductionUsage: "En accédant et en utilisant notre plateforme et nos services, vous acceptez sans réserve les présentes CGU.",
        IntroductionAcceptance: "Veuillez les lire attentivement avant de continuer à utiliser notre plateforme et nos services.",

        DefinitionsTitle: "2. Définitions",
        Fournisseur: "Fournisseur",
        FournisseurDescription: "désigne notre société, fournisseur de logiciels SaaS de type WorkSpace, Work System, Work OS, CRM, ERP, Gestion de projet et Ticketing Solution en full no-code, proposant des services de type licences utilisateurs, licences d'applications et des services d'accompagnement au paramétrage d'éléments divers (architecture, documents, templates, API, etc.).",
        Client: "Client",
        ClientDescription: "désigne toute personne physique ou morale qui utilise les services proposés par notre société, que ce soit en tant qu'utilisateur final ou en tant qu'administrateur autorisé par l'utilisateur final.",
        Utilisateur: "Utilisateur",
        UtilisateurDescription: "désigne toute personne physique ou morale qui utilise le site et/ou les services proposés par notre société.",
        Site: "Site",
        SiteDescription: "désigne le site web https://betool.fr et ses sous-domaines, ainsi que toute application mobile ou autre plateforme utilisée pour accéder aux services proposés par notre société.",
        Services: "Services",
        ServicesDescription: "désigne l'ensemble des services proposés par notre société, tels que les licences d'utilisation de notre logiciel, les services d'accompagnement pour le paramétrage et la personnalisation de nos solutions, ainsi que tout autre service proposé par notre société.",
        LicenceUtilisateur: "Licence utilisateur",
        LicenceUtilisateurDescription: "désigne l'autorisation d'utiliser notre logiciel et ses fonctionnalités, accordée par notre société à l'utilisateur moyennant le paiement d'un abonnement ou d'une redevance.",
        LicenceApplication: "Licence d'application",
        LicenceApplicationDescription: "désigne l'autorisation d'utiliser une application développée par notre société pour être intégrée dans le logiciel de l'utilisateur, accordée par notre société moyennant le paiement d'un abonnement ou d'une redevance.",

        objectTitle: "3. Objet des CGU",
        objectDescription1: "Les présentes Conditions Générales d'Utilisation (CGU) ont pour objet de définir les modalités de mise à disposition du site internet et des services proposés par www.betool.fr, ainsi que les conditions d'accès et d'utilisation de ces services par les utilisateurs. Ces CGU régissent l'ensemble des relations entre la société et les utilisateurs de son site, que ceux-ci soient simples visiteurs ou abonnés.",
        objectDescription2: "L'utilisation du site et de ses services implique l'acceptation sans réserve des présentes CGU par l'utilisateur. Si l'utilisateur n'accepte pas les termes des CGU, il est invité à quitter immédiatement le site et à ne pas utiliser les services proposés.",
        objectDescription3: "La société se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU. Il est donc recommandé à l'utilisateur de consulter régulièrement la dernière version des CGU disponible sur le site.",
        objectDescription4: "Les CGU ne créent aucun contrat de société, de mandat, de franchise ou de relation de travail entre la société et l'utilisateur.",


        conditionsTitle: "4. Conditions d'utilisation",
        conditionsDescription1: "L'Utilisateur s'engage à utiliser les services proposés par le Fournisseur en toute bonne foi et conformément aux présentes CGU, à la réglementation en vigueur et aux usages.",
        conditionsDescription2: "L'accès aux services proposés par le Fournisseur est réservé aux personnes majeures juridiquement capables de souscrire des contrats en droit français.",
        conditionsDescription3: "L'Utilisateur est seul responsable de l'utilisation des services proposés par le Fournisseur et des données qu'il enregistre, transmet ou reçoit dans le cadre de ces services. L'Utilisateur s'engage à ne pas porter atteinte à l'ordre public, aux bonnes mœurs, aux droits de tiers ou à la sécurité du Fournisseur.",
        conditionsDescription4: "L'Utilisateur s'engage à fournir des informations exactes, complètes et à jour lors de son inscription aux services proposés par le Fournisseur. Il s'engage à informer le Fournisseur de toute modification de ces informations dans les plus brefs délais.",
        conditionsDescription5: "Le Fournisseur se réserve le droit de suspendre ou de résilier l'accès aux services proposés en cas de non-respect des présentes CGU ou en cas de violation de la réglementation en vigueur.",

        accessTitle: "5. Accès au site",
        accessDescription1: "Le site https://betool.fr permet à l'Utilisateur un accès aux services suivants :",
        accessService1: "Fournisseur de logiciel/progiciel/plateforme SaaS",
        accessService2: "Achat ou location de licences utilisateurs",
        accessService3: "Achat ou location de licences d'applications proposées sur la plateforme",
        accessService4: "Catégorie du produit (Work System, Work OS, CRM, ERP, Gestion de projet et Ticketing Solution)",
        accessService5: "Type de produit : no-code.",
        accessDescription2: "Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.",
        accessDescription3: "L’Utilisateur non membre n'a pas accès aux services réservés. Pour cela, il doit s’inscrire en remplissant le formulaire. En acceptant de s’inscrire aux services réservés, l’Utilisateur membre s’engage à fournir des informations sincères et exactes concernant son état civil et ses coordonnées, notamment son adresse email. Pour accéder aux services, l’Utilisateur doit ensuite s'identifier à l'aide de son identifiant et de son mot de passe qui lui seront communiqués après son inscription. Tout Utilisateur membre régulièrement inscrit pourra également solliciter sa désinscription en se rendant à la page dédiée sur son espace personnel. Celle-ci sera effective dans un délai raisonnable.",
        accessDescription4: "Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site ou du serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas la responsabilité du Fournisseur. Dans ces cas, l’Utilisateur accepte ainsi de ne pas tenir rigueur à l’éditeur de toute interruption ou suspension de service, même sans préavis.",
        accessDescription5: "L'Utilisateur a la possibilité de contacter le site par messagerie électronique à l’adresse email du Fournisseur : contact@betool.fr.",

        intellectualPropertyTitle: "6. Propriété intellectuelle",
        intellectualPropertyDescription1: "Les marques, logos, signes ainsi que tous les contenus du site (textes, images, sons…) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.",
        intellectualPropertyDescription2: "L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre strictement privé ; toute utilisation à des fins commerciales ou publicitaires est strictement interdite.",
        intellectualPropertyDescription3: "Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet, constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle.",
        intellectualPropertyDescription4: "Il est rappelé, conformément à l’article L122-5 du Code de la propriété intellectuelle, que l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.",

        privacyTitle: "7. Confidentialité et protection des données",
        privacyDescription1: "Nous attachons une grande importance à la confidentialité et à la protection des données de nos clients. Dans le cadre de notre activité de fournisseur de services de gestion de données, nous collectons et traitons des données à caractère personnel de nos clients.",
        privacyCollectDataTitle: "Collecte des données",
        privacyCollectDataDescription: "Nous prenons très au sérieux la confidentialité et la protection de vos données personnelles. Nous ne collectons que les informations nécessaires à la fourniture de nos services, et nous ne partageons jamais ces informations avec des tiers sans votre consentement préalable. Lorsque vous utilisez nos services, nous pouvons collecter des informations telles que votre nom, votre adresse email, votre adresse IP, votre localisation, etc. Ces informations sont utilisées pour personnaliser et améliorer votre expérience utilisateur, ainsi que pour répondre à vos demandes et besoins.",
        privacyUseDataTitle: "Utilisation des données",
        privacyUseDataDescription: "Nous nous engageons à utiliser vos données personnelles uniquement dans le cadre de la fourniture de nos services, et à ne pas les utiliser à des fins commerciales ou publicitaires sans votre consentement préalable. Nous ne vendons pas vos données à des tiers et nous ne les partageons qu'avec les prestataires de services tiers qui nous aident à fournir nos services (par exemple, des fournisseurs de stockage cloud). Nous pouvons également utiliser vos données pour vous envoyer des communications liées à nos services, telles que des mises à jour, des promotions, etc.",
        privacyDataStorageTitle: "Stockage et sécurité des données",
        privacyDataStorageDescription: "Nous prenons des mesures de sécurité raisonnables pour protéger vos données personnelles contre tout accès, utilisation, altération ou divulgation non autorisé. Nous utilisons des technologies de cryptage, des pare-feux, des mots de passe et des contrôles d'accès pour protéger vos données personnelles. Nous stockons vos données dans des centres de données sécurisés et surveillons en permanence notre infrastructure pour détecter toute activité suspecte.",
        privacyUserRightsTitle: "Droits des utilisateurs",
        privacyUserRightsDescription: "Conformément aux lois applicables sur la protection des données, vous avez le droit de demander l'accès, la rectification, la suppression et la portabilité de vos données personnelles. Vous pouvez également demander la limitation du traitement de vos données personnelles ou vous opposer à leur traitement à des fins de marketing direct. Nous nous engageons à répondre rapidement et efficacement à toutes les demandes de nos utilisateurs concernant leurs données personnelles.",
        privacyDataRetentionTitle: "Durée de conservation des données",
        privacyDataRetentionDescription: "Nous ne conservons vos données personnelles que pendant la durée nécessaire à la fourniture de nos services ou à des fins légales ou réglementaires. Nous détruisons vos données personnelles de manière sécurisée lorsque nous n'en avons plus besoin ou lorsque vous nous demandez de le faire.",
        privacyPolicyLink: "Vous pouvez consulter la page dédiée à la politique de confidentialité.",


        userPublicationTitle: "8. Publication par l’Utilisateur",
        userPublicationDescription1: "Le site permet aux utilisateurs de publier les contenus suivants :",
        userPublicationTemplate: "template",
        userPublicationTextContent: "contenu textuel",
        userPublicationPhotoVideo: "photo, image ou vidéo",
        userPublicationDescription2: "Dans ses publications, l'utilisateur s’engage à respecter les règles de la nétiquette (règles de bonne conduite sur Internet) et les règles de droit en vigueur.",
        userPublicationDescription3: "Le site peut exercer une modération sur les publications et se réserve le droit de refuser leur mise en ligne, sans avoir à s’en justifier auprès de l'utilisateur.",
        userPublicationDescription4: "L'utilisateur reste titulaire de l’intégralité de ses droits de propriété intellectuelle. Mais en publiant une publication sur le site, il cède à la société éditrice le droit non exclusif et gratuit de représenter, reproduire, adapter, modifier, diffuser et distribuer sa publication, directement ou par un tiers autorisé, dans le monde entier, sur tout support (numérique ou physique), pour la durée de la propriété intellectuelle. L'utilisateur cède notamment le droit d'utiliser sa publication sur Internet et sur les réseaux de téléphonie mobile.",
        userPublicationDescription5: "La société éditrice s'engage à faire figurer le nom de l'utilisateur à proximité de chaque utilisation de sa publication.",
        userPublicationDescription6: "Tout contenu mis en ligne par l'Utilisateur est de sa seule responsabilité. L'Utilisateur s'engage à ne pas mettre en ligne de contenus pouvant porter atteinte aux intérêts de tierces personnes. Tout recours en justice engagé par un tiers lésé contre le site sera pris en charge par l'Utilisateur.",
        userPublicationDescription7: "Le contenu de l'Utilisateur peut être à tout moment et pour n'importe quelle raison supprimé ou modifié par le site, sans préavis.",

        responsibilityTitle: "9. Responsabilité",
        responsibilityDescription1: "Les sources des informations diffusées sur le site www.betool.fr sont réputées fiables, mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions. Les informations communiquées sont présentées à titre indicatif et sans valeur contractuelle. Malgré des mises à jour régulières, le site www.betool.fr ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être tenu responsable de l’utilisation et de l’interprétation des informations contenues dans ce site.",
        responsibilityDescription2: "L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que soit sa forme, est interdite. Il assume les risques liés à l'utilisation de son identifiant et de son mot de passe. Le site décline toute responsabilité dans ce cas de figure.",
        responsibilityDescription3: "Le site www.betool.fr ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, un accès ou un téléchargement provenant de ce site. La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.",

        paymentTermsTitle: "10. Modalités de paiement",
        paymentTermsDescription1: "Le paiement de nos services s'effectue selon les modalités suivantes :",
        paymentTermsItem1: "Pour les services souscrits à l'achat de licences utilisateurs, le paiement se fait par avance et le montant est fixé en fonction du nombre de licences souscrites. Le paiement peut être effectué par virement bancaire, carte bancaire ou tout autre moyen de paiement accepté par notre société.",
        paymentTermsItem2: "Pour les services souscrits à la location de licences utilisateurs, le paiement s'effectue mensuellement ou annuellement, selon les modalités définies dans le contrat de location. Le paiement peut être effectué par virement bancaire, carte bancaire ou tout autre moyen de paiement accepté par notre société.",
        paymentTermsItem3: "Les services d'accompagnement au paramétrage sont facturés à l'heure, selon les tarifs en vigueur. Le paiement peut être effectué par virement bancaire, carte bancaire ou tout autre moyen de paiement accepté par notre société.",
        paymentTermsDescription2: "Tous les paiements doivent être effectués dans les délais impartis. En cas de retard de paiement, notre société se réserve le droit de suspendre ou de résilier le service en question, sans préjudice des autres droits et recours qui pourraient être exercés. Tout paiement effectué après la date limite entraînera automatiquement une majoration de 10 % des sommes dues, sans préjudice des autres intérêts de retard.",
        paymentTermsDescription3: "En cas de non-paiement, notre société se réserve le droit d'engager toute action en justice qui serait nécessaire pour recouvrer les sommes dues.",
        paymentTermsDescription4: "Tous les frais bancaires liés aux paiements sont à la charge du client.",

        terminationSuspensionTitle: "11. Résiliation et suspension",
        userTerminationTitle: "Résiliation à l'initiative de l'utilisateur",
        userTerminationDescription: "L'utilisateur peut résilier son contrat d'utilisation à tout moment en envoyant une demande écrite de résiliation par courrier recommandé à l'adresse email du fournisseur : contact@betool.fr. La résiliation prendra effet à la fin de la période d'abonnement en cours, sauf en cas de violation des conditions générales d'utilisation, auquel cas le fournisseur se réserve le droit de mettre fin immédiatement au contrat d'utilisation sans préavis ni indemnité.",
        providerTerminationTitle: "Résiliation à l'initiative du fournisseur",
        providerTerminationDescription: "Le fournisseur se réserve le droit de résilier le contrat d'utilisation en cas de non-respect des conditions générales d'utilisation par l'utilisateur. Le cas échéant, la résiliation prendra effet immédiatement sans préavis ni indemnité. Le fournisseur peut également résilier le contrat d'utilisation à tout moment, moyennant un préavis de 30 jours, sans avoir à justifier sa décision. Le cas échéant, le fournisseur remboursera à l'utilisateur le montant correspondant à la période d'abonnement restant à courir.",
        terminationConsequencesTitle: "Conséquences de la résiliation",
        terminationConsequencesDescription: "En cas de résiliation du contrat d'utilisation, quelle qu'en soit la raison, l'utilisateur s'engage à cesser immédiatement toute utilisation du service et à restituer au fournisseur l'ensemble des documents, données et informations qui lui ont été confiés dans le cadre de l'utilisation du service. Le fournisseur ne sera pas tenu responsable des dommages directs ou indirects causés à l'utilisateur ou à des tiers du fait de la résiliation du contrat d'utilisation.",
        dataArchivingTitle: "Archivage des données",
        dataArchivingDescription: "Suite à la résiliation du contrat d'utilisation, le fournisseur s'engage à conserver les données de l'utilisateur pendant une période de 1 an à compter de la date de résiliation. Passé ce délai, le fournisseur pourra procéder à la suppression définitive de ces données, sauf si l'utilisateur en demande expressément la conservation pour une durée supplémentaire. Dans tous les cas, le fournisseur garantit la confidentialité et la sécurité des données stockées.",

        lawJurisdictionTitle: "12. Loi applicable et juridiction compétente",
        lawApplicableDescription: "Les présentes CGU sont soumises à la loi française.",
        disputeResolutionDescription: "En cas de litige survenant entre les parties à l'occasion de l'exécution des présentes, les parties conviennent de tenter de régler le différend à l'amiable. À défaut d'accord amiable dans un délai de trente (30) jours à compter de la notification du litige par l'une des parties à l'autre, le différend sera soumis aux tribunaux compétents de Paris, nonobstant pluralité de défendeurs ou appel en garantie.",
        userFinalDisclaimerDescription: "Il est rappelé que la société éditrice n'est qu'un prestataire technique pour le compte de l'utilisateur final. La société éditrice ne pourra être tenue pour responsable de tout litige survenant entre l'utilisateur final et un tiers. Tout litige devra être résolu directement entre l'utilisateur final et le tiers concerné, sans impliquer la responsabilité de la société éditrice.",
        independenceDisclaimerDescription: "Les parties reconnaissent expressément que les présentes CGU ne créent aucune communauté de droit, ni aucun mandat, ni aucune société entre les parties et que chacune conserve sa pleine et entière indépendance.",
        waiverDescription: "Toute renonciation à exercer un droit prévu par les présentes CGU ne pourra être interprétée comme une renonciation à exercer tout autre droit ou à la même disposition à un autre moment.",
        articleTitlesDisclaimerDescription: "Les titres des articles des présentes CGU ont été inclus pour plus de commodité, mais n'affecteront pas la signification ou l'interprétation des présentes CGU.",

        hyperlinkTitle: "13. Liens hypertextes",
        hyperlinkDescription: "Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira du site https://betool.fr. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.",

        cookiesTitle: "14. Cookies",
        cookiesDescription1: "L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation.",
        cookiesDescription2: "Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur de l’Utilisateur par son navigateur, et qui sont nécessaires à l’utilisation du site https://betool.fr. Les cookies ne contiennent pas d’informations personnelles et ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de l’Utilisateur, d’autres restent.",
        cookiesDescription3: "L’information contenue dans les cookies est utilisée pour améliorer le site https://betool.fr.",
        cookiesDescription4: "En naviguant sur le site, l’Utilisateur les accepte.",
        cookiesDescription5: "L’Utilisateur doit toutefois donner son consentement quant à l’utilisation de certains cookies.",
        cookiesDescription6: "À défaut d’acceptation, l’Utilisateur est informé que certaines fonctionnalités ou pages risquent de lui être refusées.",
        cookiesDescription7: "L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.",

        // dmcaPolicy
        dmcaPolicyTitle: "POLITIQUE DE RETRAIT DMCA",
        dmcaPolicyEffectiveDate: "En vigueur au 01/01/2025",
        dmcaPolicyDescription1: "Notre société respecte le Digital Millennium Copyright Act (DMCA) et prend très au sérieux les plaintes pour violation de droits d'auteur. Si vous êtes propriétaire d'un droit d'auteur ou de toute autre propriété intellectuelle et que vous pensez que notre site web ou nos services enfreignent vos droits, nous vous encourageons à nous contacter immédiatement.",
        dmcaPolicyDescription2: "Pour soumettre une notification de violation de droits d'auteur, vous devez fournir une communication écrite qui inclut les informations suivantes :",
        dmcaPolicyDescription3: "Une signature électronique ou physique de la personne autorisée à agir au nom du propriétaire des droits d'auteur ou d'autres droits de propriété intellectuelle ;",
        dmcaPolicyDescription4: "Une description de l'œuvre protégée par des droits d'auteur ou d'autres droits de propriété intellectuelle que vous revendiquez avoir été violée ;",
        dmcaPolicyDescription5: "Une description de l'emplacement sur notre site web ou dans nos services où se trouve le contenu que vous réclamez en violation de droits d'auteur ou d'autres droits de propriété intellectuelle ;",
        dmcaPolicyDescription6: "Votre adresse, numéro de téléphone et adresse email ;",
        dmcaPolicyDescription7: "Une déclaration de votre part selon laquelle vous croyez de bonne foi que l'utilisation contestée n'est pas autorisée par le propriétaire des droits d'auteur, ses agents ou la loi ;",
        dmcaPolicyDescription8: "Une déclaration de votre part, sous peine de parjure, selon laquelle les informations ci-dessus dans votre notification sont exactes et que vous êtes le propriétaire des droits d'auteur ou de la propriété intellectuelle, ou que vous êtes autorisé à agir au nom du propriétaire des droits d'auteur ou de la propriété intellectuelle.",
        dmcaPolicyDescription9: "Veuillez envoyer votre notification à notre agent désigné pour les notifications de violation de droits d'auteur à l'adresse suivante : contact@betool.fr.",
        dmcaPolicyDescription10: "Une fois que nous recevons une notification de violation de droits d'auteur conforme aux exigences ci-dessus, nous nous engageons à retirer ou à désactiver l'accès au contenu en question dans un délai raisonnable. Nous prendrons également des mesures pour informer l'utilisateur ayant téléchargé le contenu en question de la violation alléguée et lui donnerons la possibilité de soumettre une réponse conforme au DMCA. Si l'utilisateur soumet une réponse en bonne et due forme, nous pourrions être amenés à réactiver le contenu signalé.",
        dmcaPolicyDescription11: "Nous nous réservons le droit de supprimer tout contenu sans préavis, à notre seule discrétion, si nous déterminons qu'il enfreint les droits de propriété intellectuelle d'un tiers.",
        dmcaPolicyDescription12: "Nous encourageons tous nos utilisateurs à respecter les droits de propriété intellectuelle des autres. Si vous êtes un utilisateur de notre site web ou de nos services et que vous croyez qu'un autre utilisateur a violé vos droits de propriété intellectuelle, veuillez nous contacter immédiatement.",

// POLITIQUE DE CONFIDENTIALITÉ
        privacyPolicyTitle: "POLITIQUE DE CONFIDENTIALITÉ",
        privacyPolicyEffectiveDate: "En vigueur au 01/01/2025",
        privacyPolicyDescription1: "betool.fr",
        privacyPolicyDescription0: "Technician Soft Solution",
        privacyPolicyDescription00: "Type de site : Logiciel SaaS",
        privacyPolicyDescription2: "But de la politique de confidentialité",
        privacyPolicyDescription25: "Le but de cette politique de confidentialité est de définir les modalités de traitement des données personnelles, dans le cadre d'une relation commerciale de confiance, notamment entre le fournisseur du site www.betool.fr et les utilisateurs du site, dans les domaines suivants :",
        privacyPolicyDataCollected: "Les données personnelles recueillies",
        privacyPolicyDataUsage: "L’utilisation des données recueillies",
        privacyPolicyDataAccess: "L’accès aux données recueillies",
        privacyPolicyUserRights: "Les droits des utilisateurs du site",
        privacyPolicyCookiePolicy: "La politique de cookies du site",
        privacyPolicyDescription3: "Cette politique de confidentialité fonctionne parallèlement aux conditions générales d’utilisation de notre site.",

        applicableLaws: "Lois applicables",
        gdprCompliance: "Conformément au Règlement général sur la protection des données (RGPD), cette politique de confidentialité est conforme aux lois et règlements suivants : <br> - Règlement (UE) 2016/679 du Parlement Européen du 27 avril 2016<br> - Loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés<br> - Loi 2018-493 du 2 juin 2018 relative à la protection des données personnelles. <br> Les données à caractère personnel doivent être :",
        legalBasisLawfulness1: "traitées de manière licite, loyale et transparente au regard de la personne concernée (licéité, loyauté, transparence) ;",
        legalBasisLawfulness2: "collectées pour des finalités déterminées, explicites et légitimes, et ne pas être traitées ultérieurement d'une manière incompatible avec ces finalités ; le traitement ultérieur à des fins archivistiques dans l'intérêt public, à des fins de recherche scientifique ou historique ou à des fins statistiques n'est pas considéré, conformément à l'article 89, paragraphe 1, comme incompatible avec les finalités initiales (limitation des finalités) ;",
        legalBasisLawfulness3: "adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées (minimisation des données) ;",
        legalBasisLawfulness4: "exactes et, si nécessaire, tenues à jour ; toutes les mesures raisonnables doivent être prises pour que les données à caractère personnel qui sont inexactes, eu égard aux finalités pour lesquelles elles sont traitées, soient effacées ou rectifiées sans tarder (exactitude) ;",
        legalBasisLawfulness5: "conservées sous une forme permettant l'identification des personnes concernées pendant une durée n'excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont traitées ; les données à caractère personnel peuvent être conservées pour des durées plus longues dans la mesure où elles seront traitées exclusivement à des fins archivistiques dans l'intérêt public, à des fins de recherche scientifique ou historique ou à des fins statistiques conformément à l'article 89, paragraphe 1, pour autant que soient mises en œuvre les mesures techniques et organisationnelles appropriées requises par le règlement afin de garantir les droits et libertés de la personne concernée (limitation de la conservation) ;",
        legalBasisLawfulness6: "traitées de façon à garantir une sécurité appropriée des données à caractère personnel, y compris la protection contre le traitement non autorisé ou illicite et contre la perte, la destruction ou les dégâts d'origine accidentelle, à l'aide de mesures techniques ou organisationnelles appropriées (intégrité et confidentialité).",
        californiaPrivacyCompliance: "Pour les résidents de l’État de Californie, cette politique de confidentialité vise à se conformer à la California Consumer Privacy Act (CCPA). S’il y a des incohérences entre ce document et la CCPA, la législation de l’État s’appliquera. Si nous constatons des incohérences, nous modifierons notre politique pour nous conformer à la loi pertinente.",

        consent: "Consentement",
        consent1: "Les utilisateurs conviennent qu’en utilisant notre site, ils consentent :",
        consentConditions: "aux conditions énoncées dans la présente politique de confidentialité et",
        consentDataCollection: "à la collecte, l’utilisation et la conservation des données énumérées dans la présente politique.",
        personalDataCollection: "Données personnelles que nous collectons",
        automaticallyCollectedData: "Données personnelles collectées automatiquement",
        automaticallyCollectedDataDescription: "Lorsque vous visitez et utilisez notre site, nous pouvons automatiquement recueillir et conserver les renseignements suivants :",
        ipAddress: "Adresse IP",
        location: "Lieu",
        hardwareSoftwareDetails: "Détails matériels et logiciels",
        linksClicked: "Liens sur lesquels un utilisateur clique en utilisant le site",
        contentViewed: "Contenu consulté par l’utilisateur sur le site",
        browsingHistoryActions: "Historique de navigation et actions des utilisateurs",
        nonAutomaticallyCollectedData: "Données recueillies de façon non automatique",
        nonAutomaticallyCollectedDataDescription: "Nous pouvons également collecter les données suivantes lorsque vous effectuez certaines actions sur notre site :",
        firstNameLastName: "Prénom et nom",
        age: "Âge",
        dateOfBirth: "Date de naissance",
        gender: "Sexe",
        phoneNumber: "Numéro de téléphone",
        address: "Domicile",
        paymentInformation: "Informations de paiement",
        autofillData: "Données de remplissage automatique",
        dataCollectionMethods: "Ces données peuvent être recueillies au moyen des méthodes suivantes :",
        dataCollectionNote: "Veuillez noter que nous ne collectons que les données qui nous aident à atteindre l’objectif énoncé dans cette politique de confidentialité. Nous ne recueillerons pas de données supplémentaires sans vous en informer au préalable.",

        howWeUsePersonalData: "Comment nous utilisons les données personnelles",
        howWeUsePersonalData1: "Les données personnelles recueillies sur notre site seront utilisées uniquement aux fins précisées dans la présente politique ou indiquées sur les pages pertinentes de notre site. Nous n’utiliserons pas vos données au-delà de ce que nous avons divulgué.",
        automaticallyCollectedDataUsage: "Les données que nous recueillons automatiquement sont utilisées aux fins suivantes :",
        improveUserExperience: "Améliorer l'expérience utilisateur",
        improveSolution: "Améliorer la solution",
        keepAccessActionLogs: "Tenir un registre des accès et des actions pour les historiques",
        certifyElectronicSignatures: "Certifier les signatures électroniques",
        nonAutomaticallyCollectedDataUsage: "Les données que nous recueillons lorsque l’utilisateur exécute certaines fonctions peuvent être utilisées aux fins suivantes :",
        viaBrowser: "Via le navigateur",
        dataSharing: "Les tiers avec qui nous partageons les données personnelles",
        employees: "Employés",
        employeeDisclosure: "Nous pouvons divulguer à tout membre de notre organisation les données utilisateur dont il a raisonnablement besoin pour réaliser les objectifs énoncés dans la présente politique.",
        thirdParties: "Tierces parties",
        thirdPartiesDataUsage: "Nous pouvons partager les données utilisateur avec les tiers suivants :",
        thirdPartiesDataAccess: "Les tiers ne seront pas en mesure d’accéder aux données des utilisateurs au-delà de ce qui est raisonnablement nécessaire pour atteindre l’objectif donné.",
        otherDisclosures: "Autres divulgations",
        lawRequirement: "si la loi l'exige",
        legalProceedings: "si cela est requis pour toute procédure judiciaire",
        protectLegalRights: "si nous devons prouver ou protéger nos droits légaux",
        businessSale: "si nous devons transmettre des informations aux acquéreurs potentiels de la société et à leurs conseils",
        hyperlinkDisclaimer: "Si vous suivez des hyperliens de notre site vers un autre site, veuillez noter que nous ne sommes pas responsables et n’avons pas de contrôle sur les politiques et pratiques de confidentialité de ces sites.",
        dataRetention: "Combien de temps nous stockons les données personnelles",
        dataRetentionNote: "Nous ne conservons pas les données des utilisateurs au-delà de ce qui est nécessaire pour atteindre les fins pour lesquelles elles sont recueillies.",

        howWeProtectPersonalData: "Comment nous protégeons vos données personnelles.",
        howWeProtectPersonalData1: "Afin d’assurer la protection de votre sécurité, nous utilisons le protocole de sécurité de transport des données pour transmettre des renseignements personnels dans notre système.<br><br>Toutes les données stockées dans notre système sont bien sécurisées et ne sont accessibles qu’à nos employés. Nos employés sont liés par des accords de confidentialité stricts, et toute violation de cet accord entraînerait le licenciement de l'employé.",
        transportLayerSecurity: "Nous utilisons le protocole de sécurité de la couche transport pour transmettre des renseignements personnels dans notre système.",
        dataStorageSecurity: "Toutes les données stockées dans notre système sont bien sécurisées et ne sont accessibles qu’à nos employés. Nos employés sont liés par des accords de confidentialité stricts, et toute violation de cet accord entraînerait le licenciement de l'employé.",
        employeeConfidentialityAgreements: "Nos employés sont liés par des accords de confidentialité stricts, et toute violation de cet accord entraînerait le licenciement de l'employé.",
        internetInsecurity: "Alors que nous prenons toutes les précautions raisonnables pour nous assurer que nos données utilisateur sont sécurisées et que les utilisateurs sont protégés, il reste toujours un risque de préjudice. L’Internet dans son ensemble peut parfois être peu sûr, et nous sommes donc dans l'incapacité de garantir la sécurité des données des utilisateurs au-delà de ce qui est raisonnablement mis en œuvre.",
        minors: "Mineurs",
        minorDataCollection: "Le RGPD précise que les personnes de moins de 15 ans sont considérées comme des mineurs aux fins de la collecte de données. Les mineurs doivent avoir le consentement d’un représentant légal pour que leurs données soient recueillies, traitées et utilisées.",
        userRights: "Vos droits en tant qu’utilisateur",
        gdprRights: "En vertu du RGPD, les utilisateurs ont les droits suivants en tant que personnes concernées :",
        rightOfAccess: "droit d’accès",
        rightOfRectification: "droit de rectification",
        rightOfErasure: "droit à l’effacement",
        rightOfRestriction: "droit à la restriction de traitement",
        rightOfDataPortability: "droit à la portabilité des données",
        rightOfObjection: "droit d'objection",
        gdprRightsDetails: "Vous trouverez de plus amples informations sur ces droits au chapitre 3 (art. 12-23) du RGPD.",
        modifyDeleteContestData: "Comment modifier, supprimer ou contester les données recueillies",
        privacyAgent: "Si vous souhaitez que vos renseignements soient supprimés ou modifiés d’une façon ou d’une autre, veuillez communiquer avec notre agent de protection de la vie privée ici :",

        cookiePolicy: "Politique sur les cookies",
        cookieExplanation: "Un cookie est un petit fichier, stocké sur le disque dur d’un utilisateur par le site Web. Son but est de recueillir des données relatives aux habitudes de navigation de l’utilisateur.",
        functionalCookies: "Cookies fonctionnels :",
        functionalCookiesDescription: "Nous les utilisons pour mémoriser toutes les sélections que vous faites sur notre site afin qu’elles soient sauvegardées pour vos prochaines visites.",
        analyticalCookies: "Cookies analytiques :",
        analyticalCookiesDescription: "Cela nous permet d’améliorer la conception et la fonctionnalité de notre site en recueillant des données sur le contenu auquel vous accédez et que vous consultez en utilisant notre site.",
        targetingCookies: "Cookies de ciblage :",
        targetingCookiesDescription: "Ces cookies collectent des données sur la façon dont vous utilisez le site et vos préférences. Cela nous permet de rendre les informations que vous voyez sur notre site plus ciblées pour vous.",
        cookieSettings: "Vous pouvez choisir d’être averti chaque fois qu’un cookie est transmis. Vous pouvez également choisir de désactiver complètement les cookies dans votre navigateur Internet, mais cela peut diminuer la qualité de votre expérience d’utilisation.",
        thirdPartyCookies: "Cookies tiers",
        thirdPartyCookies1: "Nous pouvons utiliser des cookies tiers sur notre site pour atteindre les objectifs suivants :",
        enhanceSecurity: "Améliorer la sécurité",
        modifications: "Modifications",
        modificationsDescription: "Cette politique de confidentialité peut être modifiée à l’occasion afin de maintenir la conformité avec la loi et de tenir compte de tout changement dans notre processus de collecte de données. Nous recommandons à nos utilisateurs de vérifier notre politique régulièrement pour s’assurer qu’ils soient informés de toute mise à jour. Au besoin, nous pouvons informer les utilisateurs par courriel des changements apportés à cette politique.",
        contactDescription: "Si vous avez des questions à nous poser, n’hésitez pas à communiquer avec nous en utilisant ce qui suit :",

        supportRequ: "Demande de support",
        leadRequ: "Demande d'informations :",
        companyName: "Nom de l'entreprise :",
        problemDescription: "Description de votre demande de support :",
        problemDescriptionL: "Description de votre besoin :",
        phoneContact: "Téléphone :",
        emailContact: "Email :",

        companyNameRequired: "Le nom de l'entreprise est requis",
        requestSubjectRequired: "Le sujet de la demande est requis",
        phoneNumberRequired: "Le numéro de téléphone est requis",
        emailRequired: "L'email est requis",
        emailInvalid: "L'email doit être valide",
        apiDocumentation: "Documentation API",

        content: "Contenu",
        free: "Gratuit",
        plus: "Plus",

        featuresList: "Liste des fonctionnalités",
        numberOfBoards: "Nombre de tableaux",
        proFieldTypes: "Types de champ pro",
        fileUpload: "Chargement de fichiers",
        documentStorage: "Stockage documents",
        organization: "Organisation",
        businessUnit: "Unité d'affaire",
        role: "Rôle",
        advancedPermissionManagement: "Gestion des permissions avancée",
        user: "Utilisateur",
        max2: "Max 2",
        min3: "Min 3",
        min10: "Min 10",
        templatesAndPlugins: "Templates, applications et plug-ins",
        basicTemplate: "Template basic",
        proTemplate: "Template pro",
        basicPlugins: "Application et plug-in basic",
        proPlugins: "Application et plug-in pro",
        expertPlugins: "Application et plug-in expert",
        sharingAndCollaboration: "Partage et collaboration",
        publicAPI: "API publique",
        automationTasks: "Automatisation (task)",
        viewsAndProcessing: "Vues et traitement",
        dataCenterView: "Vue tableau - datacenter colonne",
        mapAndGeolocation: "Vue MAP et géolocalisation",
        kanbanViews: "Vues Kanban",
        calendarAndPlanning: "Calendrier et planning",
        branding: "Image de marque",
        whiteLabel: "Marque blanche (URL)",
        unlimited: "Illimitée",

    }
}
