<template>
    <div>
        <section id="CONFI" class="container" style="padding-top: 120px">
            <div>
                <h1>{{ $t('message.privacyPolicyTitle') }}</h1>
                <p class="text-center mb-15">{{ $t('message.privacyPolicyEffectiveDate', { date: '01/01/2025' }) }}</p>
<!--                <p><span style="color: #0770cf; font-weight: bold; font-size: 1.1rem">{{ $t('message.privacyPolicyDescription1') }}</span> <span>By</span> <span style="font-weight: bold; font-size: 1.1rem">{{ $t('message.privacyPolicyDescription0') }}</span> </p>-->
<!--                <p style="font-weight: bold;">{{ $t('message.privacyPolicyDescription00') }}</p>-->
                <p class="titleConfi">{{ $t('message.privacyPolicyDescription2') }}</p>
                <p>{{ $t('message.privacyPolicyDescription25') }}</p>
                <ul>
                    <li><span>{{ $t('message.privacyPolicyDataCollected') }}</span></li>
                    <li><span>{{ $t('message.privacyPolicyDataUsage') }}</span></li>
                    <li><span>{{ $t('message.privacyPolicyDataAccess') }}</span></li>
                    <li><span>{{ $t('message.privacyPolicyUserRights') }}</span></li>
                    <li><span>{{ $t('message.privacyPolicyCookiePolicy') }}</span></li>
                </ul>
                <p>{{ $t('message.privacyPolicyDescription3') }}</p>
            </div>

            <div>
                <p class="titleConfi">{{ $t('message.applicableLaws') }}</p>
                <p v-html="$t('message.gdprCompliance')"></p>
                <ul>
                    <li><span>{{ $t('message.legalBasisLawfulness1') }}</span></li>
                    <li><span>{{ $t('message.legalBasisLawfulness2') }}</span></li>
                    <li><span>{{ $t('message.legalBasisLawfulness3') }}</span></li>
                    <li><span>{{ $t('message.legalBasisLawfulness4') }}</span></li>
                    <li><span>{{ $t('message.legalBasisLawfulness5') }}</span></li>
                    <li><span>{{ $t('message.legalBasisLawfulness6') }}</span></li>
                </ul>
<!--                <p>{{ $t('message.californiaPrivacyCompliance') }}</p>-->
            </div>

            <div>
                <p class="titleConfi">{{ $t('message.consent') }}</p>
                <p>{{ $t('message.consent1') }}</p>
                <ul>
                    <li><span>{{ $t('message.consentConditions') }}</span></li>
                    <li><span>{{ $t('message.consentDataCollection') }}</span></li>
                </ul>
            </div>
            <div>
                <p class="titleConfi">{{ $t('message.personalDataCollection') }}</p>
                <div>
                    <div>
                        <p>{{ $t('message.automaticallyCollectedData') }}</p>
                        <p>{{ $t('message.automaticallyCollectedDataDescription') }}</p>
                        <ul>
                            <li><span>{{ $t('message.ipAddress') }}</span></li>
                            <li><span>{{ $t('message.location') }}</span></li>
                            <li><span>{{ $t('message.hardwareSoftwareDetails') }}</span></li>
                            <li><span>{{ $t('message.linksClicked') }}</span></li>
                            <li><span>{{ $t('message.contentViewed') }}</span></li>
                            <li><span>{{ $t('message.browsingHistoryActions') }}</span></li>
                        </ul>
                    </div>
                    <div>
                        <p class="titleConfi">{{ $t('message.nonAutomaticallyCollectedData') }}</p>
                        <p>{{ $t('message.nonAutomaticallyCollectedDataDescription') }}</p>
                        <ul>
                            <li><span>{{ $t('message.firstNameLastName') }}</span></li>
                            <li><span>{{ $t('message.age') }}</span></li>
                            <li><span>{{ $t('message.dateOfBirth') }}</span></li>
                            <li><span>{{ $t('message.gender') }}</span></li>
                            <li><span>{{ $t('message.email') }}</span></li>
                            <li><span>{{ $t('message.phoneNumber') }}</span></li>
                            <li><span>{{ $t('message.address') }}</span></li>
                            <li><span>{{ $t('message.paymentInformation') }}</span></li>
                            <li><span>{{ $t('message.autofillData') }}</span></li>
                        </ul>
                        <p>{{ $t('message.dataCollectionMethods') }}</p>
                        <p>{{ $t('message.dataCollectionNote') }}</p>
                    </div>
                </div>
            </div>

            <div>
                <p class="titleConfi">{{ $t('message.howWeUsePersonalData') }}</p>
                <p>{{ $t('message.howWeUsePersonalData1') }}</p>
                <div>
                    <p>{{ $t('message.automaticallyCollectedDataUsage') }}</p>
                    <ul>
                        <li><span>{{ $t('message.improveUserExperience') }}</span></li>
                        <li><span>{{ $t('message.improveSolution') }}</span></li>
                        <li><span>{{ $t('message.keepAccessActionLogs') }}</span></li>
                        <li><span>{{ $t('message.certifyElectronicSignatures') }}</span></li>
                    </ul>
                </div>
<!--                <div>-->
<!--                    <p>{{ $t('message.nonAutomaticallyCollectedDataUsage') }}</p>-->
<!--                    <ul>-->
<!--                        <li value="1" style="margin-bottom: 0pt;"><span>{{ $t('message.viaBrowser') }}</span></li>-->
<!--                    </ul>-->
<!--                </div>-->
            </div>
            <div>
                <p class="titleConfi">{{ $t('message.dataSharing') }}</p>
                <div>
                    <p class="titleConfiP">{{ $t('message.employees') }}</p>
                    <p>{{ $t('message.employeeDisclosure') }}</p>
                </div>
                <div>
                    <p class="titleConfiP">{{ $t('message.thirdParties') }}</p>
<!--                    <p>{{ $t('message.thirdPartiesDataUsage') }}</p>-->
                    <p>{{ $t('message.thirdPartiesDataAccess') }}</p>
                </div>
                <div>
                    <p class="titleConfiP">{{ $t('message.otherDisclosures') }}</p>
                    <ul>
                        <li><span>{{ $t('message.lawRequirement') }}</span></li>
                        <li><span>{{ $t('message.legalProceedings') }}</span></li>
                        <li><span>{{ $t('message.protectLegalRights') }}</span></li>
                        <li><span>{{ $t('message.businessSale') }}</span></li>
                    </ul>
                    <p>{{ $t('message.hyperlinkDisclaimer') }}</p>
                </div>
            </div>
            <div>
                <p class="titleConfi">{{ $t('message.dataRetention') }}</p>
                <p>{{ $t('message.dataRetentionNote') }}</p>
            </div>

            <div>
                <p class="titleConfi">{{ $t('message.howWeProtectPersonalData') }}</p>
                <p v-html="$t('message.howWeProtectPersonalData1')"></p>
                <div>
<!--                    <p>{{ $t('message.transportLayerSecurity') }}</p>-->
<!--                    <p>{{ $t('message.dataStorageSecurity') }}</p>-->
                    <p>{{ $t('message.employeeConfidentialityAgreements') }}</p>
                    <p>{{ $t('message.internetInsecurity') }}</p>
                </div>
            </div>
            <div>
                <p class="titleConfi">{{ $t('message.minors') }}</p>
                <p>{{ $t('message.minorDataCollection') }}</p>
            </div>
            <div>
                <p class="titleConfi">{{ $t('message.userRights') }}</p>
                <div>
                    <p>{{ $t('message.gdprRights') }}</p>
                    <ul>
                        <li><span>{{ $t('message.rightOfAccess') }}</span></li>
                        <li><span>{{ $t('message.rightOfRectification') }}</span></li>
                        <li><span>{{ $t('message.rightOfErasure') }}</span></li>
                        <li><span>{{ $t('message.rightOfRestriction') }}</span></li>
                        <li><span>{{ $t('message.rightOfDataPortability') }}</span></li>
                        <li><span>{{ $t('message.rightOfObjection') }}</span></li>
                    </ul>
                    <p>{{ $t('message.gdprRightsDetails') }}</p>
                </div>
            </div>
            <div>
                <p class="titleConfi">{{ $t('message.modifyDeleteContestData') }}</p>
                <p>{{ $t('message.privacyAgent') }}</p>
                <p style="text-align: left; margin-left: 20pt;"><a href="mailto:contact@betool.fr">contact@betool.fr</a></p>
            </div>

            <div>
                <p class="titleConfi">{{ $t('message.cookiePolicy') }}</p>
                <p>{{ $t('message.cookieExplanation') }}</p>
                <ul>
                    <li><span>{{ $t('message.functionalCookies') }}</span>{{ $t('message.functionalCookiesDescription') }}</li>
                    <li><span>{{ $t('message.analyticalCookies') }}</span>{{ $t('message.analyticalCookiesDescription') }}</li>
                    <li><span>{{ $t('message.targetingCookies') }}</span>{{ $t('message.targetingCookiesDescription') }}</li>
                </ul>
                <p>{{ $t('message.cookieSettings') }}</p>
            </div>
            <div>
                <p class="titleConfi">{{ $t('message.thirdPartyCookies') }}</p>
                <p>{{ $t('message.thirdPartyCookies1') }}</p>
                <ul>
                    <li><span>{{ $t('message.improveUserExperience') }}</span></li>
                    <li><span>{{ $t('message.enhanceSecurity') }}</span></li>
                </ul>
            </div>
            <div>
                <p class="titleConfi">{{ $t('message.modifications') }}</p>
                <p>{{ $t('message.modificationsDescription') }}</p>
            </div>
            <div class="mb-10">
                <p class="titleConfi">{{ $t('message.contact') }}</p>
                <p>{{ $t('message.contactDescription') }} <a href="mailto:contact@betool.fr">contact@betool.fr</a></p>
            </div>
            <hr>

        </section>
    </div>
</template>

<script>
export default {
    name: "politiqueC"
}
</script>

<style scoped>

</style>